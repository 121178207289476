@import 'config/bundle';

.switch {
    opacity: 0;
    --inner-color: var(--color-primary-20);
    --icon-color: var(--color-primary);
    --handle-background-color: var(--color-white-dimmed);
    --handle-shadow-color: var(--color-primary-40);

    composes: cleanButton from global;
    padding: 2px 0;
    cursor: pointer;
    z-index: var(--z-index-very-low);

    &:focus-visible .handle {
        box-shadow: 0 0 0 2px var(--color-code-primary-80), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 4px 4px 16px 0 rgba(0, 0, 0, 0.2);
    }
}

.switchExpert {
    --inner-color: var(--color-green-10);
    --icon-color: var(--color-white-dimmed);
    --handle-background-color: var(--color-green);
    --handle-shadow-color: var(--color-green);

    .inner {
        box-shadow: inset 0 0 0 2px var(--color-green);
    }
    .icon {
        margin: 0 8px;
    }
    .iconDeactivated {
        --icon-color: var(--color-green);
    }
}

.switchReady {
    opacity: 1;
}

.inner {
    height: 40px;
    display: flex;
    align-items: stretch;
    border-radius: 20px;
    background-color: var(--inner-color);
    position: relative;
}

.option {
    display: flex;
    align-items: center;
    position: relative;
}

.icon {
    width: 24px;
    margin: 0 8px;
    position: relative;
    z-index: var(--z-index-low);

    path {
        stroke: var(--icon-color);
        transition: var(--easing-primary) var(--timing-normal) stroke;
    }

    @include media-breakpoint-up(lg) {
        margin: 0 10px;
    }
}

.text {
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    white-space: nowrap;
    color: var(--color-primary);
    text-transform: uppercase;
    position: relative;
    z-index: var(--z-index-low);
    margin-right: 15px;
    display: none;

    @include media-breakpoint-up(lg) {
        display: initial;
    }
}

.handle {
    // width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    border-radius: 20px;
    background-color: var(--handle-background-color);
    box-shadow: 0 0 0 2px var(--handle-shadow-color), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 4px 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.handleOff {
    composes: handle;
    left: 0;
}

.handleOn {
    composes: handle;
    left: 50%;
}
