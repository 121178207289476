.controls {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 50px;
    margin-bottom: 20px;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.buttons {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
