.root {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    color: var(--color-white);
    height: 30px;
}

.rootBig {
    composes: root;
    padding: 0 8px;
    border-radius: var(--border-radius);
}

.rootSmall {
    composes: root;
    border-radius: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    padding: 0 7px;
    line-height: 0;
    text-transform: uppercase;
}

.icon {
    width: 20px;
}

.splashTypeOE {
    background-color: var(--color-primary);

    &:hover {
        background-color: var(--color-primary-80);
    }
}

.splashType4 {
    background-color: var(--color-yellow);

    &:hover {
        background-color: var(--color-yellow-50);
    }
}

.splashTypeS {
    background-color: var(--color-red);

    &:hover {
        background-color: var(--color-red-50);
    }
}
