@import 'config/bundle';

.basketFooter {
    padding: 20px 0;

    display: grid;
    gap: 10px;
    @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 10px;
    }
}

.basketFooterButtonsWrapper {
    display: grid;
    gap: 10px;
    @include media-breakpoint-up(md) {
        display: flex;
        margin-left: auto;
        align-items: center;
        column-gap: 20px;
        row-gap: 10px;
    }
}
