@import 'config/bundle';

.root {
    padding-top: 20px;
}

.controls {
    display: flex;
    gap: var(--grid-gutter-half);
    padding-left: 10px;
}

.vehicleInfoWrapper {
    display: flex;
    gap: var(--grid-gutter-half);
}

.icon {
    width: 24px;
    cursor: pointer;
}

.mobileOnly {
    @include media-breakpoint-up(xl) {
        display: none;
    }
}
.desktopOnly {
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.removeWrapper {
    @include media-breakpoint-up(xl) {
        text-align: center;
    }
}

.buttonWrapper {
    @include media-breakpoint-up(xl) {
        display: flex;
        justify-content: flex-end;
    }
}
