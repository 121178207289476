@import 'config/bundle';

.withBgColor {
    padding: 20px;
}

.title {
    composes: caption from global;
    margin-bottom: 15px;
}

.link {
    composes: link-underline from global;
    display: block;
    font-size: 13px;

    &:not(:last-child) {
        margin-bottom: 7px;
    }
}
