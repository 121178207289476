@import 'config/bundle';

.card {
    border: 1px solid var(--color-line);
    margin: 20px 0;
    padding: 20px;
    display: grid;
    gap: 20px;

    @include media-breakpoint-up(lg) {
        display: flex;
        column-gap: 20px;
        align-items: flex-end;
    }
}

.imageContainer {
    flex: 1;
}

.vehicleInfoText {
    margin-top: 10px;
}
