@import 'config/bundle';

.button {
    composes: cleanButton from global;
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    font-weight: var(--font-weight-semi-bold);
    border-radius: 4px;

    &:disabled,
    &.fetching {
        background-color: var(--color-disabled);
        color: var(--color-white);

        svg {
            path {
                stroke: var(--color-white);
            }
        }
    }

    svg {
        width: 20px;
        path {
            stroke: var(--color-white);
        }
    }
}

.defaultSize {
    composes: button;
    column-gap: 10px;
    height: 40px;
    padding: 0 20px;

    &.iconOnly {
        width: 40px;
        padding: 0;
    }
}

.smallSize {
    composes: button;
    column-gap: 5px;
    height: 30px;
    padding: 0 10px;

    &.iconOnly {
        width: 30px;
        padding: 0;
    }
}

.linkSize {
    composes: button;
}
.fullWidth {
    width: 100%;
}

.primaryStyle:not([disabled]) {
    background-color: var(--color-orange);
    color: var(--color-white);

    &:hover {
        background-color: var(--color-orange-50);
        color: var(--color-white);
    }
    &:focus {
        outline: 3px solid var(--color-primary-60);
    }
}

.secondaryStyle:not([disabled]) {
    background-color: var(--color-primary);
    color: var(--color-white);

    &:hover {
        background-color: var(--color-primary-60);
        color: var(--color-white);
    }
    &:focus {
        outline: 3px solid var(--color-orange-50);
    }
}

.secondaryLightStyle:not([disabled]) {
    background-color: var(--color-primary-20);
    color: var(--color-primary);

    &:hover {
        background-color: var(--color-primary-10);
        color: var(--color-primary);
    }

    svg {
        path {
            stroke: var(--color-primary);
        }
    }
}

.tertiaryStyle:not([disabled]) {
    background-color: var(--color-primary-140);
    color: var(--color-white);

    &:hover {
        background-color: var(--color-primary-60);
        color: var(--color-white);
    }
    &:focus {
        outline: 3px solid var(--color-primary-60);
    }
}

.cleanStyle:not([disabled]) {
    background-color: transparent;
    color: var(--color-primary);

    &:hover {
        color: var(--color-primary-60);
        svg {
            path {
                stroke: var(--color-primary-60);
            }
        }
    }

    svg {
        path {
            stroke: var(--color-primary);
        }
    }
}

.content {
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
    white-space: nowrap;
    text-align: center;
    display: block;
    flex: 1;
}

.loaderWrapper {
    position: absolute;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
