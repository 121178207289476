@import 'config/bundle';

.errorBox {
    padding: 10px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    font-weight: var(--font-weight-semi-bold);
}

.error {
    color: var(--color-error);
    background-color: var(--color-background-error);
    border: 1px solid var(--color-error);

    path {
        stroke: var(--color-error);
    }
}

.warning {
    color: var(--color-warning-dark);
    background-color: var(--color-background-warning);
    border: 1px solid var(--color-warning-dark);

    path {
        stroke: var(--color-warning-dark);
    }
}

.warningIcon {
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
    margin-right: 10px;
    align-self: center;
}

.text {
    flex: 1;
    align-self: center;
}

.dismissButton {
    composes: cleanButton from global;
    background-color: var(--color-error);
    width: 20px;
    height: 20px;
    margin-left: 10px;
    flex: 0 0 auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dismissIcon {
    width: 10px;
    height: 10px;

    path {
        stroke: var(--color-white);
    }
}
