.counter {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    font-size: 18px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: -0.33px;
}

.digit {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--color-primary-20);
}

.divider {
    display: inline-block;
    margin: 0 5px;
}
