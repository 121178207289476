.button {
    composes: cleanButton from global;
    display: block;
    grid-column: 1/-1;
    margin: 20px auto 0 auto;
    width: 100%;
    max-width: 830px;
    height: 300px;

    &::-webkit-scrollbar {
        display: none;
    }
}
