.loaderWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--color-code-white), 0.7);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    position: fixed;
}

.iPaperWrapper {
    position: relative;
    aspect-ratio: 16 / 9;
}

.frame {
    z-index: 1;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
