@import 'config/bundle';

.wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 25px 12px 25px;
}

.modalWrapper {
    background-color: var(--color-primary-5);
    padding-left: 10px;

    @include media-breakpoint-up(md) {
        padding-left: var(--grid-gutter-half);
    }
    @include media-breakpoint-up(lg) {
        padding-left: var(--grid-gutter-width);
    }
}
