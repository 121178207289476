@import 'config/bundle';

.filterListContainer {
    width: 100%;
}

.filterList {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-columns: 0;
    gap: 20px 15px;

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));
    }
}

.filterListVisible {
    composes: filterList;
    display: flex;
    align-items: flex-end;
}

.filterListHidden {
    composes: filterList;

    &.isOpen {
        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }
    }
}

.filterItem {
    width: 100%;
    background: var(--color-white);

    &:last-child {
        margin-bottom: 25px;
    }

    @include media-breakpoint-up(lg) {
        width: auto;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
