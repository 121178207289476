@import 'config/bundle';

.content {
    @include media-breakpoint-up(md) {
        width: 100%;
        padding: var(--grid-gutter-half) var(--grid-gutter-width);
    }
}

.uspList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    margin: 0;
    list-style-type: none;
    background: var(--background-color);

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
}

.itemContent {
    display: flex;
}

.imagePlaceholder {
    display: flex;
    justify-content: center;
    height: 198px;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    margin: 0 -5px 25px;
    object-fit: contain;
}
