@import 'config/bundle';

.filters {
    margin-top: 15px;
    display: grid;
    gap: 20px;
    @include media-breakpoint-up(lg) {
        grid-template-columns: auto 1fr 1fr;
    }
}

.search {
    margin-left: auto;
    width: 100%;
    @include media-breakpoint-up(lg) {
        max-width: 400px;
    }
}

.filterPlaceholder {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.smallDropdown {
    height: 40px;
    width: 100%;
    @include media-breakpoint-up(lg) {
        max-width: 170px;
    }

    label {
        position: relative;
        display: block;
        height: 100%;
        padding-right: 24px;

        span {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            font-size: 10px;
            line-height: 7px;
            padding-left: 15px;
            margin-top: 9px;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            font-weight: var(--font-weight-semi-bold);
        }
    }

    select {
        position: absolute;
        inset: 0;
        border: var(--input-border-default);
        padding: 14px 35px 0 15px;
        font-weight: var(--font-weight-semi-bold);

        appearance: none;
        -webkit-appearance: none;
        background: transparent;
        cursor: pointer;
    }

    .caret {
        width: 15px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
}
