@import 'config/bundle';

.documents {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-gap: 40px 20px;
}

.documentsList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.link {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    border-bottom: 1px solid var(--color-line);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0;
    line-height: 21px;
}

.linkIcon {
    width: 15px;

    path {
        stroke: var(--color-primary);
    }
}
