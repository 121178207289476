.root {
    border: 1px solid var(--color-line);
    border-bottom: none;
}

.row {
    cursor: pointer;
    &:hover {
        background-color: var(--color-background-blue);
    }
}

.buttonWrapper {
    padding: 10px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--color-line);
}

.buttonCell {
    pointer-events: none;
}
