@import 'config/bundle';

.heading {
    display: flex;
    gap: 10px;
}

.overview {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.details {
    margin-bottom: 10px;
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
}

.buttons {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.price {
    color: var(--color-primary);
    @include media-breakpoint-up(xl) {
        text-align: right;
    }
}
