@import 'config/bundle';

.buttonGroup {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        margin-top: 60px;
    }
}
