@import 'config/bundle';

.divider {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--color-black-30);
    margin: 0px 0px 30px;
}

.button {
    margin-top: -15px;
    top: 15px;
}
