@import 'config/bundle';

.links {
    list-style: none;
    padding: 0;
    column-width: 300px;
    column-gap: var(--grid-gutter-width);
    margin-bottom: var(--grid-gutter-width);
}

.link {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 16px 10px 15px;
    font-size: 18px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: -0.33px;
    line-height: 28px;
    border-bottom: 1px solid var(--color-black-30);
}

.item {
    color: var(--color-black-50);
}

.text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.stock {
    white-space: nowrap;
    padding-left: 20px;
    font-size: 13px;
    line-height: 21px;
    display: flex;
    align-items: center;
    margin-left: auto;
}
.stockIcon {
    width: 15px;
    height: 15px;
    margin-right: 3px;
}
