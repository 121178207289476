@import 'config/bundle';

.addToBasketButton {
    z-index: var(--z-index-very-low);
    display: flex;
    align-items: center;
    border: none;
    appearance: none;
    border-radius: var(--border-radius-btn);
    padding: 0 10px;
    height: 30px;
    min-width: 30px;
    font-size: 14px;
    color: var(--color-white-dimmed);
    background-color: var(--color-orange);

    &:disabled {
        background-color: var(--color-disabled);
    }
}

.addToBasketButtonLarge {
    padding: 0 20px;
    height: 40px;
    min-width: 40px;
}

.addToBasketButtonSquare {
    padding: 0 10px;
    justify-content: center;
}

.addToBasketButtonLabel {
    display: none;
    line-height: 0;
    color: var(--color-white-dimmed);

}

.showLabelAtAlways {
    composes: addToBasketButtonLabel;
    display: inline-block;
}

.showLabelAtMD {
    composes: addToBasketButtonLabel;

    @include media-breakpoint-up(md) {
        display: inline-block;
    }
}

.showLabelAtLG {
    composes: addToBasketButtonLabel;

    @include media-breakpoint-up(lg) {
        display: inline-block;
    }
}

.showLabelAtXL {
    composes: addToBasketButtonLabel;

    @include media-breakpoint-up(xl) {
        display: inline-block;
    }
}

.addToBasketButtonShowLabelAtXXL {
    composes: addToBasketButtonLabel;

    @include media-breakpoint-up(xxl) {
        display: inline-block;
    }
}

.addToBasketButtonIcon {
    width: 20px;

    &:not(:last-child) {
        margin-right: 5px;
    }

    path {
        stroke: var(--color-white-dimmed);
    }
}
