@import 'config/bundle';

.formGrid {
    @include media-breakpoint-up(xl) {
        display: grid;
        gap: 20px 50px;
        grid-template-columns: repeat(12, 1fr);
    }
}

.fieldset {
    margin-bottom: var(--grid-gutter-width);
}

.requiredStar {
    font-size: 14px;
    color: var(--color-primary);
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: -3px;
}

.requiredCarInfo {
    border: 1px solid var(--color-primary-20);
    padding: 1rem;
    margin-bottom: 20px;
}

.caseType {
    composes: fieldset;
    grid-column: span 4;
}

.dateRangeGrid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.col {
    grid-column: span 4;
}

.textInput {
    margin-bottom: 15px;
}

.descriptionCol {
    grid-column: span 8;
}

.description {
    min-height: 130px;
}

.radio {
    margin-bottom: 15px;
}

.formLegend {
    &::before {
        content: '*';
        display: inline-block;
        color: var(--color-primary);
        margin-right: 2px;
    }
}

.checkbox {
    margin-top: 10px;
    display: block;
    margin-bottom: 20px;
}

.submitButton {
    margin-top: 10px;
}

.emailInput {
    scroll-margin-top: 400px;
}
