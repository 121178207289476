@import 'config/bundle';

.caption {
    display: flex;
    column-gap: 10px;
}

.content {
    padding: 2px 2px 42px 2px; /* Else it will be hidden from collapse, because we are using box-shadow */
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

.facet {
    font-weight: var(--font-weight-regular);
    white-space: nowrap;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    color: var(--color-black);
    box-shadow: 0 0 0 1px var(--color-black-30);
    transition: box-shadow var(--easing-primary) var(--timing-normal), background-color var(--easing-primary) var(--timing-normal),
        color var(--easing-primary) var(--timing-normal);
    background-color: var(--color-white-dimmed);
    appearance: none;
    border: none;
    margin: 5px;

    &:focus {
        outline: none;
    }
}
.disabled {
    color: rgba(var(--color-code-black), 0.3);
    box-shadow: var(--shadow-focus);
    cursor: inherit;
}
.selected {
    color: var(--color-black);
    box-shadow: 0 0 0 2px var(--color-primary);
    background-color: var(--color-black-10);
}
