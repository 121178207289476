.select {
    margin-bottom: 20px;
}

.item {
    min-height: 200px;
}

.details {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
}

.errorBox {
    margin: 10px 0;
}
