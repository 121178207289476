.root {
    max-width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    display: inline-block;
    padding-right: 20px;

    &:hover {
        .button {
            transform: translateX(5px);
            opacity: 1;
            z-index: 0;
        }
    }
}

.button {
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    opacity: 0;
    composes: cleanButton from global;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(0);
    z-index: -1;
    transition-property: opacity, transform;
    transition-duration: var(--timing-normal);
    transition-timing-function: var(--easing-primary);
    border-radius: 4px;

    &:hover {
        .icon {
            path {
                stroke: var(--color-primary);
            }
        }
    }
}

.icon {
    width: 20px;

    path {
        stroke: var(--color-primary-40);
    }
}
