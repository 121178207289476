@import 'config/bundle';

.wrapper {
    width: 20px;
    display: flex;
    justify-content: center;
    position: relative;
    left: -10px;
}

.button {
    position: relative;
    composes: cleanButton from global;
    background-color: var(--color-primary-10);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.placeholder {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--color-black-30);
    position: relative;
}

.icon {
    transition: transform var(--timing-normal) var(--easing-primary);
    width: 20px;
    path {
        stroke: var(--color-primary);
    }
}

.iconOpen {
    composes: icon;
    transform: rotate(45deg);
}
