@import 'config/bundle';

.gallery {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}

.sliderImageContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    @include media-breakpoint-up(sm) {
        border: 1px solid var(--color-black-10);
    }
}

.nextBtn,
.prevBtn {
    composes: cleanButton from global;
    top: calc(50%);
    position: absolute;
    background: var(--color-primary);
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-focus);

    svg {
        width: 24px;

        path {
            stroke: var(--color-white-dimmed);
        }
    }
}

.nextBtn {
    right: -50px;
}

.prevBtn {
    left: -50px;
}

.escBtn {
    top: 0px;
}
