@import 'config/bundle';

.section {
    display: grid;
    grid-template-rows: repeat(2, minmax(300px, auto));
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: var(--grid-gutter-half);

    @include media-breakpoint-up(xxl) {
        column-gap: var(--grid-gutter-width);
        grid-template-rows: 500px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}

.dynamic {
    @include media-breakpoint-up(md) {
        grid-template-rows: repeat(2, auto);
    }

    @include media-breakpoint-up(xxl) {
        grid-template-rows: auto;
    }

    .media {
        min-height: 300px;
    }

    .body {
        display: block;
    }
}

.fullWidth {
    @include media-breakpoint-up(md) {
        column-gap: var(--grid-gutter-width);
        grid-template-rows: 500px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .media {
        @include media-breakpoint-up(md) {
            grid-column: span 6;
            margin-right: calc(var(--grid-gutter-half) * -1);
        }
    }

    .text {
        @include media-breakpoint-up(md) {
            grid-column: span 6;
            align-items: flex-start;
            margin-left: calc(var(--grid-gutter-half) * -1);
        }
    }

    &.flip {
        .text {
            @include media-breakpoint-up(md) {
                margin: 0 calc(var(--grid-gutter-half) * -1) 0 0;
            }
        }

        .media {
            @include media-breakpoint-up(md) {
                margin: 0 0 0 calc(var(--grid-gutter-half) * -1);
            }
        }
    }
}

.body {
    flex-grow: 0;
    overflow: hidden;
}

.inverted {
    color: var(--color-white);
}

.media {
    position: relative;
    grid-column: 1 / -1;

    @include media-breakpoint-up(xxl) {
        margin: 0;
        grid-column: span 6;
    }
}

.text {
    padding: 20px;
    display: flex;
    grid-column: 1 / -1;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-background);

    @include media-breakpoint-up(xxl) {
        margin-left: calc(var(--grid-gutter-width) * -1);
        grid-column: span 6;
        padding: 35px;
        align-items: flex-start;
    }
}

.flip {
    .text {
        order: 1;

        @include media-breakpoint-up(xxl) {
            margin: 0 calc(var(--grid-gutter-width) * -1) 0 0;
        }
    }

    .media {
        order: 2;

        @include media-breakpoint-up(xxl) {
            margin: 0;
        }
    }
}

.title {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
    }
}

.link {
    flex-shrink: 0;
    margin-top: 25px;
    background-color: var(--color-button, var(--color-primary));

    .inverted & {
        color: var(--color-primary);
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        background-color: var(--color-button, var(--color-primary));
        opacity: 0.9;

        .inverted & {
            color: var(--color-primary);
        }
    }
}

.small {
    .body {
        -webkit-line-clamp: 4;
    }

    @include media-breakpoint-up(md) {
        grid-template-rows: 300px;
    }
}
