.container {
    display: flex;
    flex-direction: column;
}

.noResults {
    margin: 100px 0;
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    text-align: center;
}

.row {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 3fr 3fr 160px;
    min-height: 44px;
    align-items: center;
}

.headerRow {
    composes: row;
    background-color: var(--color-primary-10);
    box-shadow: var(--shadow-focus);

    .cell {
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid var(--color-primary-20);

        &:first-child,
        &:last-child {
            border-left: none;
        }
    }
}

.bodyRow {
    composes: row;
    border-bottom: 1px solid var(--color-line);
    font-size: 13px;
    line-height: 21px;

    &:nth-child(even) {
        background-color: var(--color-black-5);
    }
}
.bodyRowSubmitted .cell:not(:last-child) {
    opacity: 0.5;
}

.cell {
    padding: 10px 15px;
    transition: var(--easing-primary) var(--timing-normal) opacity;

    &:first-child {
        padding-left: 35px;
    }
    &:last-child {
        display: flex;
        justify-content: flex-end;
    }
}
.cellPreformatted {
    font-family: var(--font-monospace);
    font-size: 12px;
    line-height: 16px;
}
