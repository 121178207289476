@import 'config/bundle';

.root {
    text-align: center;
}

.variantWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    text-align: left;
    width: 100%;
    padding: 16px 16px 0 16px;
    row-gap: 16px;
    column-gap: 5px;
    &:nth-child(even) {
        background-color: var(--color-black-5);
    }
    @include media-breakpoint-up(xxl) {
        &:not(.mobileView) {
            grid-template-columns: 70px 110px 120px 130px 70px 1fr;
            padding: 6px 16px 6px 16px;

            &:nth-child(even) {
                background-color: var(--color-white);
            }
            &:nth-child(odd) {
                background-color: var(--color-black-5);
            }

            .brandWrapper {
                grid-column: 1/2;
            }

            .itemNumber {
                grid-column: 2/3;
            }

            .stockInfo {
                grid-column: 4/5;
                justify-self: start;
            }

            .ftzNumber {
                justify-self: start;
                grid-column: 3/4;
            }

            .priceCell {
                grid-column: 5/6;
                grid-row: 1/2;
                justify-self: start;
            }

            .basketWrapper {
                grid-column: 6/7;
            }

            .caption {
                display: none;
            }
        }
    }
}

.brandWrapper {
    grid-column: 1/-1;
    width: 100%;
}

.itemNumber {
    grid-column: 1/2;
}

.stockInfo {
    justify-self: end;
    grid-column: 3/4;
}

.ftzNumber {
    grid-column: 2/3;
    @include media-breakpoint-up(sm) {
        justify-self: center;
    }
}

.priceCell {
    grid-row: 3/5;
    grid-column: 1/2;
    align-self: center;
    justify-self: start;
}

.basketWrapper {
    align-self: center;
    justify-items: end;
    grid-column: 3/4;
    display: grid;
    justify-content: flex-end;
    align-items: center;
}

.basketButton {
    width: auto;
}

.favoriteBtn {
    align-self: center;
    justify-self: end;
    //  grid-column: 1/3  ;
}

.caption {
    composes: caption from global;
}

//?Motion framer wrapper for variants

.tableWrapper {
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    // margin-bottom: 10px;

    &::after {
        content: '';
        transition: var(--easing-custom) 2s opacity;
        pointer-events: none;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 40px;
        background: linear-gradient(180deg, rgba(var(--color-code-white), 0) 0%, rgba(var(--color-code-white), 1) 100%);
    }
}

.tableWrapperCollapsed {
    &:after {
        opacity: 1;
    }
}

.collapseButton {
    composes: cleanButton from global;
    color: var(--color-primary-120);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;
    background-color: var(--color-primary-10);
    padding: 5px 10px;
    border-radius: var(--border-radius-btn);
    margin-top: 10px;
}
