.topmotiveRequestFormFields {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.topmotiveRequestFormField {
  grid-column: span 6;
}

.topmotiveRequestFormPhoneField {
  grid-column: span 5;
}

.topmotiveRequestFormEmailField {
  grid-column: span 7;
}

.topmotiveRequestFormFieldWide {
  grid-column: span 12;
}