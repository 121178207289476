@import 'config/bundle';
$modal-side-padding: var(--grid-gutter-width);
.root {
    position: fixed;
    inset: 0;
    z-index: var(--z-index-high);
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    z-index: 1;
    padding: 15px 0px;
    inset: 0;
    background-color: var(--color-overlay-background-black);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalWrapper {
    position: relative;
    z-index: 2;
    display: flex;
    height: 80%;
    width: 100%;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(sm) {
        width: 97%;
        height: 100%;
    }
}

.modal {
    position: relative;
    padding-left: 0px;
    padding-bottom: 10px;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);

    @include media-breakpoint-up(sm) {
        padding-left: 10px;
    }
}

.closeButton {
    z-index: var(--z-index-high);
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
}
