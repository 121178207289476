@import '../common/input-common.module.scss';

.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.input {
    composes: inputCommon;
    height: var(--input-height);
}

.showPassBtn {
    composes: cleanButton from global;
    width: auto;
    position: absolute;
    right: 20px;
    font-weight: var(--font-weight-semi-bold);
    font-size: 12px;
    color: var(--color-primary);
}

.passInput {
    padding-right: 70px;
}

.searchInput {
    padding-right: 50px;
}

.searchIcon,
.genericIcon {
    position: absolute;
    right: 15px;
    width: 24px;
    pointer-events: none;

    path {
        stroke: var(--color-primary);
    }
}
.genericIcon {
    top: 10px;
    right: 11px;
}

.genericIcon {
    top: 10px;
    right: 11px;
}

.warningWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hidden {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
}
