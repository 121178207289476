.root {
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 5px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.button {
    padding: 0;
}
