@import 'config/bundle';

.vehicleInfoWrapper {
    display: grid;
    gap: 10px;
}

.checkboxCell {
    pointer-events: none;
}

.checkbox {
    pointer-events: all;
}

.controls {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.selector {
    @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
    }
}
