@import 'config/bundle';

.table {
    width: 100%;

    @include media-breakpoint-up(xl) {
        display: table;
    }
}

.thead {
    display: none;

    @include media-breakpoint-up(xl) {
        display: table-row;
        transform: scale(1);
    }
}

.theadDefault {
    composes: thead;

    @include media-breakpoint-up(xl) {
        box-shadow: var(--shadow-focus);
        background-color: var(--color-primary-10);
    }

    .headerCell {
        border-left: 1px solid var(--color-primary-20);

        &:first-child {
            border-left: none;
        }
    }
}

.theadClean {
    composes: thead;

    @include media-breakpoint-up(xl) {
        background-color: transparent;

        .headerCell {
            border-bottom: 1px solid var(--color-line);
        }
    }
}

.row {
    &:nth-child(odd) {
        background-color: var(--color-black-5);
    }

    @include media-breakpoint-down(xl) {
        position: relative;
        padding: 10px;

        display: grid;
        gap: 10px;
        border-top: 1px solid var(--color-line);

        &:nth-child(even) {
            background-color: var(--color-black-5);
        }
        &:nth-child(odd) {
            background-color: var(--color-white-dimmed);
        }
        &:last-child {
            border-bottom: 1px solid var(--color-line);
        }
    }

    @include media-breakpoint-up(xl) {
        display: table-row;
        .cell {
            border-bottom: 1px solid var(--color-line);
        }
    }
}

.verticalAlignRow {
    @include media-breakpoint-up(xl) {
        .cell {
            vertical-align: middle;
        }
    }
}

.collapsibleRow {
    @include media-breakpoint-down(xl) {
        padding-right: 90px;
    }
}

.linkRow {
    font-weight: inherit;
    text-align: inherit;

    &:hover {
        color: var(--color-primary);
        background-color: var(--color-black-10);
    }
}

.cell {
    font-size: 13px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    padding: 0 10px;

    @include media-breakpoint-up(xl) {
        display: table-cell;
        padding: 15px 10px;
    }
}

.headerCell {
    composes: cell;
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    text-transform: uppercase;
    letter-spacing: 0.6px;
    line-height: 14px;
}

.rightSideComponent {
    margin-left: 30px;
    width: 30px;
}

.cellLabel {
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.hideOnMobile {
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.expandButton {
    composes: cleanButton from global;
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;
    height: 100%;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.expandButtonIcon {
    width: 24px;
    transform: rotate(45deg);
    transition: transform var(--timing-normal) var(--easing-primary);

    path {
        stroke: var(--color-primary);
    }

    &.expanded {
        transform: rotate(0deg);
    }
}
