@import 'config/bundle';

.siblingCategories {
    display: grid;
    grid-template-columns: 100%;

    @include media-breakpoint-up(xl) {
        column-gap: 20px;
        grid-template-columns: minmax(0, 1fr) auto;
        margin-top: 0;
    }

    &:global(.swiper-wrapper) {
        align-items: start;
    }

    &:global(.swiper-slide) {
        height: 100%;
    }
}

.siblingCategoriesButtonContainer {
    grid-row: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    margin-right: -16px;
    padding-bottom: 10px;

    @include media-breakpoint-up(xl) {
        display: block;
        grid-row: 1;
        grid-column: 2;
        margin-top: -5px;
        margin-right: 0;
        padding-bottom: 0;
    }
}

.siblingCategoriesButton {
    &:disabled {
        background: transparent;

        svg {
            path {
                stroke: var(--color-primary-60);
            }
        }
    }
}
