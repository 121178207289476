@import 'config/bundle';

.list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.item {
    display: grid;
    grid-template-columns: 100px 1fr 1fr 72px;
    border-bottom: 1px solid var(--color-line);

    .productId {
        display: inline-flex;
        align-items: center;
        height: 30px;
    }
}

.itemImage {
    padding: 10px;
}

.itemColumn {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 6px;
    padding: 12px;
}

.button {
    margin: 16px;
}

.messageContainer {
    padding: 12px;

    &.loading {
        text-align: center;
        text-transform: uppercase;
    }
}
