@import 'config/bundle';

.tableWrapper {
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 10px;

    &::after {
        content: '';
        transition: var(--easing-custom) 2s opacity;
        pointer-events: none;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(180deg, rgba(var(--color-code-white), 0) 0%, rgba(var(--color-code-white), 1) 100%);
    }
}

.tableWrapperCollapsed {
    &:after {
        opacity: 1;
    }
}

.collapseButton {
    composes: cleanButton from global;
    color: var(--color-primary);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;
}
