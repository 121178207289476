@import 'config/bundle';

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 30px 0;
}

.toggler {
    display: flex;
    place-items: center;
}

.label {
    composes: caption from global;
    margin: 0 10px 0 20px;
}

.noAccessContainer {
    padding: 60px 0;

    @include media-breakpoint-up(sm) {
        padding: 100px 0;
    }
}

.noAccessHeader {
    margin-bottom: 1.1rem;
}

.backButton {
    margin-top: 20px;
}

.noAccessWrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.backButton {
    margin-top: 20px;
}
