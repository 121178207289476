@import 'config/bundle';

.container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: auto;
    background: var(--color-primary-10);
    border: 1px solid var(--color-primary-40);
    margin: 25px 0;
    padding: 25px 20px;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
        grid-template-columns: auto min-content;
        grid-column-gap: 20px;
    }
}

.buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
}

.multiSelectItem {
    background-color: var(--color-primary-10);
    color: var(--color-primary);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        &:not([aria-disabled="true"]) {
            background-color: var(--color-white-dimmed);
            cursor: pointer;
        }
    }

    &[aria-disabled="true"] {
        cursor: default;
        color: var(--color-primary-20);
    }
}

.selected {
    font-weight: var(--font-weight-semi-bold);
}

.active {
    background-color: var(--color-white-dimmed);
}

.count {
    color: (var(--color-primary-60));
}