@import 'config/bundle';

.addToBasketQuantityContainer {
    z-index: var(--z-index-low);
    display: flex;
    justify-content: flex-end;
    width: auto;
    border-radius: var(--border-radius-btn);
    box-shadow: var(--shadow-focus);
    overflow: hidden;
}
