@import 'config/bundle';

.button {
    composes: cleanButton from global;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    color: var(--color-black-50);

    svg {
        width: 20px;
        path {
            stroke: var(--color-white);
        }
    }

    &:disabled {
        background-color: var(--color-disabled);
        color: var(--color-white);

        svg {
            path {
                stroke: var(--color-white);
            }
        }
    }

    &:not([disabled]) {
        background-color: var(--color-primary);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-primary-60);
            color: var(--color-white);
        }

        &:focus {
            outline: 3px solid var(--color-orange-50);
        }
    }
}
