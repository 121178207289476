@import '../common/input-common.module.scss';

.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.input {
    composes: inputCommon;
    height: var(--input-height);
    padding-right: 50px;
}

.searchIcon {
    position: absolute;
    right: 15px;
    width: 24px;
    pointer-events: none;

    path {
        stroke: var(--color-primary);
    }
}

.clearIcon {
    position: absolute;
    right: 50px;
    width: 24px;

    path {
        stroke: var(--color-primary);
    }
}
