$bgError: var(--color-yellow);
$textError: var(--color-black);

.component {
    display: grid;
    grid-template-columns: 75px // image
        repeat(2, minmax(75px, 1fr)) // product details
        repeat(2, minmax(100px, 2fr)) // select boxes
        minmax(75px, 1fr) // quantity
        minmax(180px, 1fr) // price
        60px; // remove button
    column-gap: var(--grid-gutter-half);
    align-items: center;

    .caption,
    .labelRoot {
        margin-top: 4px;
    }
}

.hasError {
    margin: 0 -1px;
    border: 1px solid $bgError;
    padding-bottom: 0;

    .deleteButton {
        background-color: $bgError;

        svg path {
            stroke: $textError;
        }
    }
}

.image {
    width: auto;
    height: 75px;
    margin-left: 0;
}

.brandWrapper {
    margin-bottom: 5px;
}

.errorBox {
    margin: 0 -15px;
    margin-top: 5px;
    border: none;
    text-align: center;
    background-color: $bgError;
    color: $textError;

    svg {
        display: none;
    }
}

.textRight {
    text-align: right;
}

.srOnly {
    visibility: hidden;
    width: 0;
    height: 0;
}

.deleteButton {
    border-radius: 50%;
}

.location {
    grid-column: 4;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
}
