@import 'config/bundle';

$badgeSize: 140px;

.spot {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
    color: var(--body-color);
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.link {
    position: absolute;
    inset: 0;
    cursor: pointer;
    z-index: var(--z-index-low);
}

.content {
    padding: 15px;

    .subHeading {
        flex-grow: 1;
    }
}

.title {
    font-size: 40px;
    line-height: 45px;
    font-weight: var(--font-weight-semi-bold);
}

.subHeading {
    font-size: 25px;
    line-height: 30px;
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 10px;
}

.bodyText {
    padding-left: 6px;
    font-size: 11px;
    line-height: 14px;
    font-weight: var(--font-weight-semi-bold);
}

.imageContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.badge {
    color: var(--badge-text-color);
    background-color: var(--badge-bg-color);
    border-radius: 50%;

    position: absolute;
    right: 20px;
    bottom: 20px;

    overflow: hidden;
    padding: 10px;
    width: $badgeSize;
    height: $badgeSize;
    text-align: center;

    & > *:nth-child(2) {
        margin-top: var(--badge-center-spacing);
    }

    & > *:last-child {
        margin-bottom: var(--badge-center-spacing);
    }

    &Text {
        display: block;
        hyphens: auto;
        text-align-last: center;
        overflow-wrap: normal;
    }

    &Large {
        font-size: 20px;
        line-height: 1em;
        font-weight: var(--font-weight-bold);
    }

    &Headline {
        font-size: 12px;
        line-height: 1.2em;
    }

    &Sub {
        font-style: normal;
        font-size: 12px;
        line-height: 1.5em;
        font-weight: var(--font-weight-semi-bold);
    }

    [aria-hidden='true'] {
        display: contents;
    }

    [aria-hidden='true']:before,
    [aria-hidden='true']:after {
        content: '';
        width: 50%;
        height: 100%;
    }

    [aria-hidden='true']:before {
        float: left;
        shape-outside: radial-gradient(farthest-side at 100%, transparent 99%, transparent);
    }

    [aria-hidden='true']:after {
        float: right;
        shape-outside: radial-gradient(farthest-side at 0, transparent 99%, transparent);
    }
}
