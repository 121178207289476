@import 'config/bundle';

.carousel {
    position: relative;
    padding: 0 0 30px;
}

.arrow {
    composes: cleanButton from global;
    position: absolute;
    bottom: 15px;
    background-color: var(--color-primary);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity var(--timing-slow) var(--easing-primary), background-color var(--timing-normal) var(--easing-primary);

    &:hover {
        background-color: var(--color-primary-80);
    }
    &::after {
        content: '';
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        position: absolute;
        display: block;
    }

    &.left {
        right: calc(60px + var(--carousel-gutter, 0px));
    }
    &.right {
        right: var(--carousel-gutter, 0);
    }
    &.visible {
        opacity: 1;
    }
    &.disabled {
        background-color: var(--color-black-10);
        pointer-events: none;
    }
}
.arrowIcon {
    width: 24px;
    height: 24px;

    path {
        stroke: var(--color-white);
    }
}

.wrapper {
    overflow: hidden;
    position: relative;
    transform: translateZ(0);
    height: calc(var(--carousel-item-height) + 30px); // 30px is to make space for shadow
    padding: 0 var(--carousel-gutter, 0);
}

.items {
    position: absolute;
    // top: 15px;
    left: var(--carousel-gutter, 0);
    height: var(--carousel-item-height);
}

.item {
    position: absolute;
    top: 0;
    left: calc((var(--carousel-item-width) + var(--carousel-padding)) * var(--carousel-item-index));
    height: var(--carousel-item-height);
    width: var(--carousel-item-width);
    user-select: none;
    pointer-events: none;
}

.arrowsMiddle {
    @include media-breakpoint-up(lg) {
        bottom: 50%;
        padding: 0px;
        &.left {
            right: auto;
        }
    }
}
