@import 'config/bundle';

.content {
    margin-top: 30px;
}

.loader {
    margin: 100px 0;
}

.grid {
    display: grid;
    gap: 5px 10px;
    grid-template-columns: auto auto;
    grid-template-areas:
        'category-name checkbox'
        'update-time button'
        'user-info button'
        'price-info button';

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 95px 140px 120px 100px 30px;
        grid-template-areas: 'category-name update-time user-info price-info button checkbox';
        align-items: center;
    }

    @include media-breakpoint-up(xl) {
        gap: 5px 20px;
    }

    @include media-breakpoint-down(lg) {
        justify-content: space-between;
    }
}
