@import 'config/bundle';

.group {
    margin-bottom: 20px;
}

.header {
    padding: 5px 0 15px 0;
    display: grid;
    gap: 20px 20px;
    grid-template-areas:
        'labels'
        'car-info'
        'order-info';

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: 'car-info labels order-info';
    }
}

.labels {
    grid-area: labels;
}

.orderInfo {
    grid-area: order-info;
}

.content {
    padding: 0 10px;
    background-color: var(--color-white-dimmed);
    border: 1px solid var(--color-primary-20);
}

.products {
    composes: cleanList from global;
}

.expectedDelivery {
    margin: 10px 0;
}

.carInfo {
    grid-area: car-info;
    display: flex;
    column-gap: 10px;

    @include media-breakpoint-down(xl) {
        align-items: center;
        border-bottom: 1px solid var(--color-primary-40);
        padding-bottom: 20px;
        justify-content: center;
    }
}

.oeBadge {
    flex-shrink: 0;
}
