@import 'config/bundle';

.headline {
    padding: 32px 0;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--color-line);
}

.container {
    background-color: var(--color-black-5);
    border: 1px solid var(--color-black-50);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

    margin-bottom: 30px;
    padding-top: 25px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 15px;
    margin-bottom: 30px;
}

.list {
    background-color: var(--color-white);
    border-top: 1px solid var(--color-line);
}
