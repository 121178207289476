.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > article:not(:last-child) {
        border-bottom: 2px solid #e5e5e5;

        padding-bottom: 10px;
    }
}

.axleInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.axleInfo {
    display: flex;
}
