@import 'config/bundle';

.root {
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
}

.invoiceNumber {
    font-size: 18px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: -0.33px;
    line-height: 28px;
}

.price {
    color: var(--color-primary);
    @include media-breakpoint-up(xl) {
        text-align: right;
    }
}
