@import 'config/bundle';

.orderHistoryRecord {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  align-items: center;
  padding: 16px;

  &:nth-child(odd) {
    background-color: var(--color-black-5);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 130px) repeat(3, minmax(min-content, 1fr));
    grid-template-rows: auto;
    justify-items: center;
    column-gap: 20px;
    padding: 8px;
  }
}

.orderHistoryRecordCaption {
  composes: caption from global;

  @include media-breakpoint-up(xxl) {
    display: none;
  }
}

.orderHistoryRecordAttribute {
  justify-self: end;
}

.date {
  @include media-breakpoint-up(xxl) {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
  }
}

.quantity {
  @include media-breakpoint-up(xxl) {
    grid-column: 3/4;
    grid-row: 1/2;
    justify-self: center;
  }
}

.invoice {
  @include media-breakpoint-up(xxl) {
    grid-column: 4/5;
    grid-row: 1/2;
    justify-self: center;
  }
}

.orderHistoryRecordAction {
  justify-self: flex-end;
}