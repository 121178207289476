@import 'config/bundle';

.grid {
    @include media-breakpoint-up(md) {
        display: grid;
        gap: var(--grid-gutter-half);
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 1fr);
        gap: var(--grid-gutter-width);
    }
}
