@import 'config/bundle';

.formGrid {
    @include media-breakpoint-up(xl) {
        display: grid;
        gap: 20px 50px;
        grid-template-columns: repeat(12, 1fr);
    }
}

.uploadCol {
    grid-column: span 4;
    margin-bottom: var(--grid-gutter-width);
}

.textInput {
    margin-bottom: 15px;
}

.infoCol {
    grid-column: span 4;
    margin-bottom: var(--grid-gutter-width);
}

.dateRangeGrid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-column: span 4;
}

.inputGroup {
    grid-column: 1/-1;
}
.checkbox {
    margin-bottom: 25px;
}
.descriptionWrapper {
    grid-column: 1/5;
    // margin-bottom: var(--grid-gutter-width);
}
.description {
    min-height: 130px;
}

.inputBox {
    padding: 10px;
    border: 1px solid var(--color-primary-20);
    margin-bottom: 20px;
}

// .requiredStar {
//   font-size: 14px;
//   color: var(--color-primary);
//   display: inline-block;
//   margin-left: 5px;
//   position: relative;
//   top: -3px;
// }
// .radioWrapper{
//   display: flex;
//   flex-direction: column;
//   gap:15px
// }
