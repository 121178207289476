.baseImage {
    height: 160px;
    position: relative;
}

.placeholder {
    composes: baseImage;
    width: 100%;
}

.imageCenter {
    composes: baseImage;
    width: 100%;
}

.imageLeft {
    composes: baseImage;
    width: calc(100% - 90px);
}
