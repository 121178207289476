.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.capitalize {
    text-transform: uppercase;
}

.list {
    list-style: none;
    padding: 0;
    gap: 5px;
    margin: 0;
}

.item {
    padding: 5px;
    background-color: var(--color-black-10);
    border-radius: 5px;
    display: inline-flex;
}
