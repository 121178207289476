@import 'config/bundle';

.loginWidget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--grid-gutter-width);
    padding-bottom: var(--grid-gutter-width);
    margin: auto 0;
    min-height: 100%;
    width: 100%;
}

.mainContent {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
        align-items: center;
    }
}

.imageWrapper {
    width: 100%;
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        flex: 3;
    }
}

.captionGroup {
    @include media-breakpoint-up(md) {
        flex: 2;
    }
}

.caption {
    text-align: center;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
        text-align: left;
        margin-bottom: 50px;
    }
}

.icon {
    width: 20px;
}

.button {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: auto;
    }
}

.loginButton {
    composes: button;
}

.ftzLoginBtn {
    composes: button;
    margin-top: var(--grid-gutter-half);
}
