.root mark {
    background-color: var(--color-yellow-30);
}

.body {
    font-size: 14px;
    letter-spacing: 0;
    // line-height: 18px;
    font-weight: var(--font-weight-semi-bold);
}

.bodyLarge {
    font-size: 18px;
    line-height: 21px;
}

.bodySmall {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
}

.bodyExtraSmall {
    font-size: 10px;
    line-height: auto;
}

.monoSmall {
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 12px;
    font-family: var(--font-monospace);
    text-transform: uppercase;
}

.monoMedium {
    font-family: var(--font-monospace);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    text-align: left;
}

.monoLarge {
    font-family: var(--font-monospace);
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
}

.overflowEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Font weight
.light {
    font-weight: var(--font-weight-light);
}

.regular {
    font-weight: var(--font-weight-regular);
}

.semiBold {
    font-weight: var(--font-weight-semi-bold);
}

.bold {
    font-weight: var(--font-weight-bold);
}

// Color

.bodyColor {
    color: var(--body-color);
}

.primaryColor {
    color: var(--color-primary);
}

.errorColor {
    color: var(--color-error);
}

.lightColor {
    color: var(--color-white);
}

// Text align
.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.secondary {
    color: var(--color-black-50);
}

.nowrap {
    white-space: nowrap;
}
