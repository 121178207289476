@import 'config/bundle';

.modalOverlayContainer {
    z-index: var(--z-index-high);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    height: 100%;

    &.right {
        grid-template-columns: 1fr minmax(0, 600px);
        grid-template-rows: 100%;

        .modalContentContainer {
            grid-column: 2;
        }
    }

    &.left {
        grid-template-columns: minmax(0, 600px) 1fr;
        grid-template-rows: 100%;
    }

    &.center {
        &.default {
            .modalContentContainer {
                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr minmax(0, 1000px) 1fr;
                    grid-template-rows: 1fr minmax(0, auto) 1fr;
                }
            }
        }

        &.small {
            .modalContentContainer {
                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr minmax(0, 700px) 1fr;
                    grid-template-rows: 1fr minmax(0, auto) 1fr;
                }
            }
        }

        .modalContent {
            @include media-breakpoint-up(md) {
                grid-column: 2;
                grid-row: 2;
            }
        }
    }
}

.modalContentContainer {
    display: grid;
    height: 100%;
}

.modalContent {
    display: none;
    z-index: var(--z-index-high);
    background: var(--color-white);
    max-width: 100%;
    grid-template-rows: max-content minmax(0, 1fr);
    height: 100%;
    overflow: hidden;

    &[data-state='open'] {
        display: grid;
    }
}

.modalOverlay {
    height: 100%;
    background-color: var(--color-overlay-background-black);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.modalHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--grid-gutter-half);
    align-items: center;
    padding: 20px calc(var(--grid-gutter-width) / 2);
    border-bottom: 1px solid var(--color-line);

    @include media-breakpoint-up(sm) {
        padding: 30px var(--grid-gutter-width);
    }
}

.modalBody {
    overflow-x: auto;
    padding: 30px calc(var(--grid-gutter-width) / 2) 50px;

    @include media-breakpoint-up(sm) {
        padding: 30px var(--grid-gutter-width) 50px;
    }
}
