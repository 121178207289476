@import 'config/bundle';

.loadMore {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
}

.text {
    line-height: 21px;

    &::first-letter {
        text-transform: uppercase;
    }
}

.progress {
    width: 100%;
    max-width: 300px;
    margin: 18px 0 25px 0;
}
