@import 'config/bundle';

.thumbnails {
    display: flex;
    height: 12px;
    width: 100%;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(sm) {
        height: 95px;
    }

    @include media-breakpoint-up(md) {
        justify-content: left;
    }
}

.thumbnail {
    composes: cleanButton from global;
    position: relative;
    margin-right: 10px;
    padding: 5px;
    max-width: 70px;
    height: 70px;
    box-shadow: inset 0 0 0 1px var(--color-black-30);
    transition: box-shadow 250ms var(--easing-primary);

    img {
        object-fit: contain;
    }
}

.active {
    box-shadow: inset 0 0 0 2px var(--color-primary);

    &::after {
        opacity: 1;
    }
}

.dotThumbnail {
    border: 0;
    border-radius: 50%;
    display: flex;
    width: 8px;
    height: 8px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary-10);
    padding: 0;
    box-shadow: none;

    &::after {
        content: none;
    }
}

.activeMobile {
    background-color: var(--color-primary);
    box-shadow: none;
}
