@import 'config/bundle';

.component {
    background-color: var(--color-black-5);
    border: 1px solid var(--color-black-50);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

    margin-bottom: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 30px;
}

.formWrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 300px));
    gap: 15px;
    padding: 6px 15px 15px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
        justify-content: flex-start;
    }
}

.autoSubmitToggle {
    grid-column: span 2;
}

.form {
    display: flex;
    flex-direction: column-reverse;
}

.productList {
    display: flex;
    flex-direction: column;

    & > * {
        border: 1px solid var(---color-black-10);
        background-color: transparent;
        padding: 22px 15px;

        &:nth-child(odd) {
            background-color: var(--color-white);
        }
    }
}

.textBlock {
    padding: 0 15px;
}

.quickScanFieldWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    align-items: center;
    column-gap: 5px;
}

.quickScanFieldLabel {
    grid-column: span 2;
}
