.product {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 440px;
    border: 1px solid var(--color-black-30);
    background-color: var(--color-white);
    padding: 28px 20px 20px 20px;
    color: var(--color-black);
    transition: box-shadow var(--easing-primary) var(--timing-quick);
    font-weight: var(--font-weight-regular);

    &:hover {
        color: var(--color-black);
    }
}

.body {
    flex: 1;
}

.footer {
    margin-top: 10px;
    position: relative;
}

.badge {
    position: absolute;
    left: 0;
    top: 0;
    z-index: var(--z-index-low);
}

.image,
.imagePlaceholder {
    display: flex;
    justify-content: center;
    height: 198px;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    margin: 0 -5px 25px;
}
.imagePlaceholder {
    object-fit: contain;
}

.imageElement {
    opacity: 0;
    transition: var(--easing-primary) opacity var(--timing-slow);
}
.imageLoaded {
    opacity: 1;
}

.brandStock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    min-height: 15px;
}

.brand {
    align-self: flex-start;
    width: 70px;
    margin-right: 10px;
}

.title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-clamp: 3;
}

.stockStatus {
    pointer-events: all;
    margin-left: auto;
    flex-shrink: 0;
    min-height: 15px;
    display: flex;
    justify-items: center;
}

.addToBasket {
    pointer-events: all;
    position: absolute;
    right: 0;
    top: 0;
}

.link {
    position: relative;
    pointer-events: all;
    color: inherit;
}

.necessity {
    position: absolute;
    top: 13px;
    left: 18px;
    padding: 10px;
    z-index: var(--z-index-medium);
    color: white;
    background-color: var(--color-primary);
    border-radius: var(--border-radius-btn);
}

.tooltip {
    position: absolute;
    bottom: 30px;
    z-index: 5;
    color: white;
    background-color: var(--color-primary-80);
    padding: 4px 8px;
    display: inline-flex;
    gap: 4px;
    border-radius: 4px;
}

.tooltipContent {
    padding: 8px 12px;
}
