@import 'config/bundle';

// @mixin phablet {
//   @media (min-width: 700px) and (max-width: 1500px) {
//     @content;
//   }
// }
// @mixin desktop {
//   @media (min-width: 1501px) {
//     @content;
//   }
// }

.root {
    position: relative;
    --box-padding: 15px;
    margin-bottom: 30px;
    border: 1px solid var(--color-primary);
}

.rootExpert {
    border: 1px solid var(--color-green);
    .detailsSection {
        background-color: var(--color-background-green);
    }

    .imageSection {
        @include media-breakpoint-down(xl) {
            border-bottom: 1px solid var(--color-green);
        }
        @include media-breakpoint-up(xl) {
            border-right: 1px solid var(--color-green);
        }
    }

    .topSection {
        @include media-breakpoint-up(xl) {
            border-bottom: 1px solid var(--color-green);
        }
    }

    .accordionButton {
        background-color: var(--color-background-green);
    }
}

.topSection {
    display: grid;

    @include media-breakpoint-up(xl) {
        border-bottom: 1px solid var(--color-primary);
        grid-template-columns: 270px 1fr;
    }
}

.bottomSection {
    padding: var(--box-padding);
}

.accordion {
    display: flex;
    flex-direction: column;
}

.imageSection {
    display: flex;
    justify-content: center;

    padding: var(--box-padding);

    @include media-breakpoint-down(xl) {
        border-bottom: 1px solid var(--color-primary);
    }
    @include media-breakpoint-up(xl) {
        border-right: 1px solid var(--color-primary);
    }
}

.nextImage {
    composes: cleanButton from global;
    height: 150px;
    max-width: 260px;
    width: 100%;
}

.svgImage {
    max-width: 100%;
    max-height: 100%;
}

.detailsSection {
    padding: var(--box-padding);
    padding-bottom: 0;
    background-color: var(--color-background-blue);
    display: flex;
    flex-direction: column;
}

.titleSection {
    flex-wrap: wrap;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 0 10px;
}

.productDetails {
    margin-bottom: 15px;
}

.detailsGrid {
    display: grid;
    gap: 5px 15px;
    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr;
    }
}

.panelRelated {
    padding: 15px 0;
}

.controls {
    display: none;
    @include media-breakpoint-up(xl) {
        display: flex;
        margin-top: auto;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 20px;
    }
}

.tabsButtons {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.tabButton {
    composes: cleanButton from global;
    padding-bottom: 15px;
    white-space: nowrap;
    position: relative;
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-primary);
    transition: color var(--timing-normal) var(--easing-primary);
    border-bottom: 4px solid transparent;
}

.tabButtonActive {
    border-bottom-color: var(--color-primary);
}

.accordionButton {
    composes: cleanButton from global;
    padding: 10px 20px;
    background-color: var(--color-background-blue);
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    font-size: 14px;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;

    border-top: 1px solid var(--color-primary-40);

    &.isOpen {
        border-bottom: 1px solid var(--color-primary-40);
        .accordionIcon {
            transform: rotate(90deg);
        }
    }
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.errorReportButtonAccordion {
    composes: accordionButton;
    order: 99999;
}

.accordionIcon {
    transition: transform var(--easing-primary) var(--timing-normal);
    width: 24px;
    path {
        stroke: var(--color-primary);
    }
}

.splashes {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px;
    z-index: 1;
    display: grid;
    gap: 5px;
}
