@import 'config/bundle';

.test {
    composes: cleanButton from global;
    position: relative;
    margin-top: -15px;
    height: 30px;
    width: auto;
    top: 15px;
    display: flex;
    align-items: center;
    background-color: var(--color-white-dimmed);
    overflow: hidden;
    border: 1px solid var(--color-black-30);
    border-radius: 15px;
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0.6px;
    line-height: 14px;
    text-transform: uppercase;
}

.buttonAnimated {
    composes: cleanButton from global;
    position: relative;
    height: 30px;
    width: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 9999px;
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0.6px;
    line-height: 14px;
    text-transform: uppercase;

    &.normal {
        border: 1px solid var(--color-black-30);
        background-color: var(--color-white-dimmed);
    }

    &.thick {
        box-shadow: var(--shadow-border-15-full-primary);
        background-color: var(--color-white);
    }

    .buttonAnimatedText {
        padding: 0 10px;

        .buttonAnimatedIcon {
            width: 20px;
            margin-right: 7px;
        }
    }

    .buttonAnimatedHidden {
        opacity: 0;
        color: var(--color-primary);
        border-left: 1px solid var(--color-black-30);
        display: inline-block;
        width: auto;
        max-width: 0;
        padding: 0;
        overflow: hidden;
        position: relative;
        transform: translateX(200px) scale(0);
        transition: all var(--timing-normal) var(--easing-primary);
    }

    @include media-breakpoint-up(lg) {
        &:hover {
            border-color: var(--color-primary-40);

            .buttonAnimatedHidden {
                opacity: 1;
                max-width: 200px;
                padding: 0 10px;
                transform: translateX(0) scale(1);
            }
        }
    }
}
