@import 'config/bundle';

.product {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--color-line);
    background-color: var(--color-black-5);
    display: grid;
    gap: 0 20px;
    justify-content: space-between;

    grid-template-columns: 1fr auto;
    grid-template-areas:
        'title title'
        'item-no stock-status'
        'price add-to-basket'
        'ftz-no add-to-basket';

    @include media-breakpoint-up(md) {
        gap: 10px 20px;
        grid-template-columns: auto auto auto;
        grid-template-areas:
            'title title title '
            'ftz-no item-no  stock-status'
            'price price add-to-basket';
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 160px 150px 120px 100px 120px;
        grid-template-areas: 'title item-no ftz-no price stock-status add-to-basket';
    }
}

.titleCell {
    grid-area: title;
}

.itemNoCell {
    grid-area: item-no;
}

.ftzNoCell {
    grid-area: ftz-no;
}

.priceCell {
    grid-area: price;
    @include media-breakpoint-down(md) {
        margin-top: 20px;
    }
}

.stockStatusCell {
    grid-area: stock-status;
}

.addToBasketCell {
    grid-area: add-to-basket;
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-up(xl) {
        align-items: center;
    }
}

.itemId {
    font-weight: var(--font-weight-regular);
}

.stockStatus {
    margin-top: 5px;
}

.hideOnMobile {
    @include media-breakpoint-down(md) {
        display: none;
    }
}
