.button {
    composes: cleanButton from global;
    padding: 5px 10px;
    position: relative;
}

.loader {
    position: absolute;
    left: 0;
    opacity: 0.5;
}

.icon {
    width: 20px;
    path {
        stroke: var(--color-primary);
    }
}

.inactive {
    opacity: 0.2;
}

.ownFavoriteIcon {
    composes: icon;
    path {
        stroke: var(--color-orange);
        fill: var(--color-orange);
    }
}

.chainFavoriteIcon {
    composes: icon;
    path {
        stroke: var(--color-primary);
        fill: var(--color-primary);
    }
}
