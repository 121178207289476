.creditEntry {
  padding: 15px 0 10px 0;
  border-bottom: 1px solid var(--color-line);
  position: relative;
}

.creditEntryContent {
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 2fr 3fr;
}

.creditEntryQuantityWarn {
  text-align: right;
}

.creditEntryFooter {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  align-items: flex-end;
}
