@import 'config/bundle';

.price {
    display: block;
    white-space: nowrap;
    font-family: var(--font-monospace);
}

.normal {
    text-decoration: none;
}

.lineThrough {
    text-decoration: line-through;
}

.small {
    font-size: 10px;
}

.medium {
    font-size: 12px;
}

.large {
    font-size: 16px;
}

.lightGrey {
    color: var(--color-black-50);
}

.grey {
    color: var(--color-black);
}

.blue {
    color: var(--color-primary);
}

.green {
    color: var(--color-green);
}

.black {
    color: var(--body-color);
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.upperCase {
    text-transform: uppercase;
}

.regular {
    font-weight: var(--font-weight-regular);
}
.semiBold {
    font-weight: var(--font-weight-semi-bold);
}
.bold {
    font-weight: var(--font-weight-bold);
}
