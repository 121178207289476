.root {
    background-color: var(--color-green-dark-70);
    padding: 5px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    align-items: center;
}

.statusResolved {
    background-color: var(--color-green-dark-70);
}

.statusWarning {
    background-color: var(--color-primary-120);
}

.informationBanner {
    background-color: var(--color-primary-20);
}

.closeBtn {
    composes: cleanButton from global;
    padding: 3px 10px;
}

.blackCloseBtnIcon {
    width: 20px;

    path {
        stroke: var(--color-black);
    }
}

.whiteCloseBtnIcon {
    width: 20px;

    path {
        stroke: var(--color-white);
    }
}
