.wrapper {
    margin-bottom: 20px;
}

.categories {
    border-bottom: 1px solid var(--color-line);
    padding-bottom: 20px;
}

.sectionTitle {
    composes: legend from global;
}

.categoriesList {
    composes: cleanList from global;
}

.listItem {
    margin-bottom: 10px;
    display: grid;
    gap: 20px;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.categoryName {
    position: relative;
    border-radius: 3px;
    padding: 3px 10px;
    background-color: var(--color-primary-20);
    color: var(--color-primary);
}

.categoryNameText {
    cursor: help;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.deleteBtn {
    composes: cleanButton from global;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: var(--color-primary-40);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -7px;
    right: -7px;
}

.childrenCount {
    text-align: right;
    white-space: nowrap;
}

.closeIcon {
    width: 10px;

    path: {
        stroke: var(--color-primary);
    }
}

.form {
    padding: 15px;
    margin: 20px 0;
    background-color: var(--color-primary-10);
    display: grid;
    gap: 20px;
    align-items: flex-end;
    grid-template-columns: 1fr auto;
}

.input {
    width: 140px;
}

.subUsersPriceInheritsWarning {
    margin-top: 10px;
}
