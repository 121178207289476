@import 'config/bundle';

.root {
    background-color: var(--color-white);
    margin-top: 10px;
    padding: 10px;
    border: 1px solid var(--color-white);
    display: grid;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    grid-template-areas:
        'title-image  quantity'
        'item-id      location'
        'price        invoice-id';

    @include media-breakpoint-up(xl) {
        grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        grid-template-areas: 'title-image item-id invoice-id location quantity price';
    }
}

.imageTitleCell {
    grid-area: title-image;
}

.quantityCell {
    grid-area: quantity;
    text-align: right;
    @include media-breakpoint-up(xl) {
        text-align: left;
    }
}

.itemIdCell {
    grid-area: item-id;
}

.priceCell {
    grid-area: price;

    margin-top: 15px;

    @include media-breakpoint-up(xl) {
        text-align: right;
        margin-top: 0;
    }
}

.location {
    grid-area: location;
}

.invoiceId {
    grid-area: invoice-id;
}

.label {
    display: none;

    @include media-breakpoint-up(xl) {
        display: block;
    }
}

.itemLink {
    @include media-breakpoint-down(xl) {
        display: flex;
        column-gap: 10px;
        position: relative;
        top: -2px;
        padding-left: 45px;
    }
}
