@import 'config/bundle';

.checkoutDeliveryAddressWrapper {
    display: grid;
    gap: 20px;
}

.checkoutDeliveryAddressSelect {
    width: 100%;
}

.checkoutDeliveryAddressTimeSlotWrapper {
    display: grid;
    gap: 15px 20px;
    flex: 1;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr;
    }
}
