@import 'config/bundle';

.checkoutDeliveryCommentTextArea {
    background-color: var(--color-white-dimmed);
    height: 115px;

    @include media-breakpoint-up(lg) {
        height: 42px;
    }
}
