@import 'config/bundle';

.licensePlate {
    display: inline-flex;
    align-items: center;
    height: 33px;
    padding: 2px;
    border-radius: 4px;
    background-color: var(--color-white-dimmed);
    box-shadow: var(--shadow-focus);
    font-family: var(--font-monospace);
}

.isTruncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;

    @include media-breakpoint-up(xl) {
        width: auto;
    }
}

.country {
    background-color: var(--color-primary);
    color: var(--color-white-dimmed);
    text-transform: uppercase;
    padding: 0 2px;
    width: 27px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 2px 0 0 2px;
    text-align: center;
}

.number {
    color: var(--body-color);
    text-align: center;
    font-weight: var(--font-weight-regular);
    padding: 0 10px;
    min-width: 90px;
}

.isLicensePLate {
    width: 100px;
}

.countryCodePLaceholder {
    width: 20px;
    path {
        stroke: var(--color-white-dimmed);
    }
}
