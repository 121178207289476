@import 'config/bundle';

.basketCheckoutSplitDeliveryOptionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;

    @include media-breakpoint-up(xl) {
        flex-direction: row;
        justify-content: flex-end;
    }
}
