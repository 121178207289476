@import 'config/bundle';

.itemsList {
    composes: cleanList from global;
}

.item {
    padding: 15px 0px;
    border-bottom: 1px solid var(--color-line);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(even) {
        background-color: var(--color-black-5);
    }
}

.manufacturer {
    opacity: 0.7;
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0.6px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
}

.tecDocNo {
    color: var(--color-primary);
    font-family: var(--font-monospace);
    font-size: 12px;
    line-height: 16px;
}
