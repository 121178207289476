@import 'config/bundle';

.wrapper {
    display: flex;
    align-items: center;
}

.text {
    display: none;

    @include media-breakpoint-up(lg) {
        font-weight: var(--font-weight-regular);
        text-transform: capitalize;
        padding: 0 10px;
        display: block;
    }
}
