@import 'config/bundle';

.skeleton {
    display: block;
    width: 100%;
    border-radius: 10px;
    animation: loading 8s ease-in-out infinite;
    background-size: 400% 100%;
    background-attachment: fixed;
}

.skeletonLight {
    composes: skeleton;
    background-image: linear-gradient(270deg, #fafafa, #eaeaea, #eaeaea, #fafafa);
}

.skeletonDark {
    composes: skeleton;
    opacity: 0.5;
    background-image: linear-gradient(270deg, var(--color-primary-40), var(--color-primary-20), var(--color-primary-20), var(--color-primary-40));
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
