.h1 {
    font-size: 40px;
    line-height: 45px;

    &.withSubHeading {
        margin-bottom: 10px;
    }
}

.h2 {
    font-size: 30px;
    line-height: 35px;

    &.withSubHeading {
        margin-bottom: 7px;
    }
}

.h3 {
    font-size: 25px;
    line-height: 30px;

    &.withSubHeading {
        margin-bottom: 5px;
    }
}

.h4 {
    font-size: 18px;
    line-height: 21px;

    &.withSubHeading {
        margin-bottom: 3px;
    }
}

// Text align
.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.secondary {
    color: var(--color-black-50);
}

.nowrap {
    white-space: nowrap;
}
