@import 'config/bundle';

.footer {
    background-color: var(--color-primary-10);
    color: var(--color-black);
    padding-bottom: var(--grid-gutter-width);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--grid-gutter-width) / 2);
    width: 100%;
    flex-direction: column;
}

.content {
    width: 100%;
    display: grid;
    gap: var(--grid-gutter-half);

    @include media-breakpoint-up(md) {
        grid-template-columns: 2fr 2fr 1fr;
        padding-top: var(--grid-gutter-width);
    }
}

.sectionTitle {
    margin-bottom: 10px;
}

.link {
    composes: link from global;
    font-size: 13px;
    display: block;
    line-height: 24px;
}
.singUpWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
        width: 100%;
        padding: var(--grid-gutter-half) 0;
    }
}
