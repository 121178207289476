@import 'config/bundle';

.root {
    position: relative;
    width: 100%;
    z-index: 1;
    border: 1px solid var(--color-line);
    margin: 5px auto;
    padding: 10px;

    &:nth-child(odd):not(.open) {
        background-color: var(--color-black-5);
    }

    &:first-child,
    &.open {
        // border: 1px solid var(--color-line);
    }
    transition: all 0.2s ease;

    &.open {
        display: block;
        margin: 20px auto;
        padding: 25px;
        z-index: 2;
        background-color: var(--color-white);
        width: calc(100% + 30px);
        transform: translateX(-15px);
        box-shadow: var(--shadow-focus);
    }
}

.checkBox {
    position: relative;
    top: 5px;
    float: left;
    margin-right: 20px;
}

.collapseButtonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        position: absolute;
        top: 15px;
        right: 20px;
    }
}
