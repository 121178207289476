@import 'config/bundle';

.dateFilter {
    display: grid;
    gap: 10px 20px;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        grid-template-columns: 160px 160px;
    }
}

.datePicker {
    max-width: 100%;
}
