.root {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

.icon {
    width: 15px;

    path {
        stroke: var(--color-error);
    }
}
