@import 'config/bundle';

.twoColumnRowWrapper {
    display: grid;
    grid-template-columns: minmax(0, 100%);
    column-gap: var(--grid-gutter-width);
    row-gap: var(--grid-gutter-half);

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}

.left {
    @include media-breakpoint-up(md) {
        grid-column: span 6;
    }
}

.right {
    @include media-breakpoint-up(md) {
        grid-column: 7 / span 6;
    }
}
