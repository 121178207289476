@import 'config/bundle';

.container {
    display: grid;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr;
    }
}
