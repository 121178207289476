.modelsList {
    composes: cleanList from global;
    padding-left: 10px;
}

.modelsListSingle {
    composes: cleanList from global;
}

.modelItem {
    border-bottom: 1px solid var(--color-black-30);

    &:last-child:not(:first-child) {
        border-bottom: none;
    }
}

.modelLink {
    padding: 10px 0;
    font-size: 13px;
    font-weight: var(--font-weight-regular);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.expertLink {
    color: var(--color-green);
    &:hover {
        color: var(--color-green-70);
    }
}

.groupTitle {
    font-weight: var(--font-weight-semi-bold);
}

.title {
    composes: groupTitle;
    color: var(--color-primary);
}

.expertTitle {
    composes: groupTitle;
    color: var(--color-green);
}
