.topmotiveProductDetails {
  border: 1px solid var(--color-primary-20);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 20px;

  span {
    display: flex;
    align-items: center;
  }
}

.topmotiveProductDetailsIcon {
  margin-right: 5px;
}