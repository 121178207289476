@import 'config/bundle';

.basketSummaryInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(xl) {
        display: block;
    }
}

.basketSummaryPriceCaption {
    composes: caption from global;
}
