.itemWrapper {
    padding: 15px 0 10px 0;
    border-bottom: 1px solid var(--color-line);
    position: relative;
}

.warning {
    font-weight: 700;
}

.fetchingLayer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-white-dimmed);
    opacity: 0.3;
}

.item {
    display: grid;
    gap: 20px;
    grid-template-columns: 2fr 2fr 3fr;
}

.itemDescription {
    grid-column: span 2;
}

.quantity {
    font-family: var(--font-monospace);
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

.dropdown {
    width: 100%;
}

.depositSection {
    margin-top: 10px;
}

.radioWrapper {
    margin-bottom: 10px;
}

.quantityWarn {
    text-align: right;
}

.itemNoLocationCell {
    display: flex;
    column-gap: 20px;
    flex-wrap: nowrap;
}

.price {
    height: 38px;
    display: flex;
    flex-direction: column;
}

.depositPrice {
    margin-top: auto;
}

.deleteButtonWrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    align-items: flex-end;
}
