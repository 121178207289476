.rte {
    p,
    ul,
    ol,
    h6 {
        margin-bottom: 15px;

        ul,
        ol {
            margin-bottom: 0;
        }
    }

    h6 {
        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
