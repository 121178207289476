@import 'config/bundle';

.addToBasketQuantityButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 0;
    padding: 0;
    overflow: hidden;
    background-color: var(--color-orange);
    color: var(--color-white-dimmed);
}

.addToBasketQuantityButtonLarge {
    width: 40px !important;
    height: 40px !important;
}

.addToBasketQuantityButtonIcon {
    width: 20px;

    path {
        stroke: var(--color-white-dimmed);
    }
}
