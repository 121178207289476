@import 'config/bundle';

.root {
    position: relative;
}

.animation {
    position: relative;
    overflow: hidden;
}

.animationCollapsed {
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
    }

    &.backgroundWhite::after {
        background: linear-gradient(180deg, rgba(var(--color-code-white), 0) 0%, var(--color-white) 100%);
    }
    &.backgroundPrimary::after {
        background: linear-gradient(180deg, rgba(var(--color-code-primary-5), 0) 0%, var(--color-primary-5) 100%);
    }
}

.textBox {
    font-size: 12px;
}

.button {
    margin-top: 10px;
    margin-right: 10px;
}

.vehicleInfoOilText {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    & > li + li {
        margin-top: 5px;
    }
}

.warningText {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    & > p {
        margin: 0;
    }
}

.icon {
    width: 20px;
    margin-right: 5px;

    path {
        stroke: var(--color-red);
    }

    circle {
        stroke: var(--color-red);
    }
}
