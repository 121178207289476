@import 'config/bundle';

.wrapper {
    background-color: var(--color-white-dimmed);
    padding: 15px;
}

.customerInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-line);
}

.description {
    display: grid;
    gap: 10px 30px;
    padding-top: 10px;
    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr;
    }
}
