.manufacturersList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 var(--grid-gutter-half);
}

.manufacturer {
    composes: cleanButton from global;
    padding: 15px 10px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-line);

    &:hover {
        background-color: var(--color-primary-10);
    }
}

.manufacturerName {
    color: var(--color-primary);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;
    flex: 1;
}

.manufacturerDetails {
    display: block;
    color: var(--color-secondary);
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
}

.icon {
    border-radius: 100%;
    width: 25px;
    height: 25px;
    background-color: var(--color-primary-20);
    padding: 5px;

    path {
        stroke: var(--color-primary);
    }
}
