@import 'config/bundle';

.wrapper {
    position: relative;
    border-left: 1px dashed var(--color-line);
    position: relative;
    &:first-child::before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        width: 20px;
        height: 25px;
        background-color: var(--color-white);
    }
    &:last-child::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -10px;
        width: 20px;
        height: 25px;
        background-color: var(--color-white);
    }
}

.contentWrapper {
    display: flex;
    align-items: center;
}

.content {
    flex: 1;
    padding: 15px 10px;
    border-bottom: 1px solid var(--color-line);

    &.selectedContent {
        background-color: var(--color-primary-5);
    }
}

.categoryName {
    grid-area: category-name;
}

.updateTime {
    grid-area: update-time;
}
.userInfo {
    grid-area: user-info;
}
.priceInfo {
    grid-area: price-info;
}

.buttonCell {
    display: flex;
    justify-content: flex-end;
    grid-area: button;
}

.checkBoxCell {
    display: flex;
    justify-content: flex-end;
    grid-area: checkbox;
}

.button {
    composes: cleanButton from global;
    white-space: nowrap;
    color: var(--color-primary);
}

.children {
    list-style: none;
    padding: 0;
    padding-left: 15px;
    @include media-breakpoint-up(lg) {
        padding-left: 25px;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 35px;
    }
}
