@import 'config/bundle';

.moduleList {
    margin-top: var(--grid-gutter-half);

    @include media-breakpoint-up(lg) {
        margin-top: var(--grid-gutter-width);
    }
}

.moduleListFull {
    width: 100%;
}

.moduleListDefault {
    composes: container-fluid from global;
}

.moduleListGap {
    gap: var(--grid-gutter-half);
}
