.manualOrders {
    margin: 5px auto 150px auto;
}

.header {
    display: flex;
    align-items: flex-start;
}

.title {
    margin-right: 20px;
}

.titleSmall {
    color: var(--color-black-50);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 10px;
    visibility: hidden;
}
.titleSmallVisible {
    visibility: visible;
}
.titleNumberSpace {
    min-width: 25px;
    display: inline-block;
    text-align: right;

    &::after {
        content: '\00a0';
    }
}

.refreshButton {
    margin-right: 20px;
}

.departmentsDropdown {
    margin-left: auto;
    min-width: 200px;
}
