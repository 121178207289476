@import 'config/bundle';

.basketSummaryGridContainer {
    display: grid;
    
    @include media-breakpoint-up(xl) {
        align-items: center;
        grid-template-columns: 1fr 350px;
    }
}

.basketSummaryWrapper {
    position: relative;
    margin-bottom: 20px;
}

.basketSummaryHeaderWrapper {
    composes: basketSummaryGridContainer;
    gap: 10px;
}

.basketSummaryHeaderTitleWrapper {
    margin-top: 5px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    @include media-breakpoint-up(xl) {
        justify-content: unset;
    }
}

.basketSummaryHeaderTitle {
    margin-right: 10px;
}

.basketSummaryCollapsibleWrapper {
    composes: basketSummaryGridContainer;
    padding-top: 20px;
    margin-top: 20px;
    align-items: flex-start;
    gap: 0 10px;
    border-top: 1px solid var(--color-primary-20);
    @include media-breakpoint-up(xl) {
        padding-bottom: 30px;
    }
}

.basketSummaryInfoWrapper {
    @include media-breakpoint-up(xl) {
        display: grid;
        gap: 10px;
        grid-template-columns: 200px 200px;
    }
}

.basketSummaryInfoFtzCode {
    visibility: var(--feature-price-visible);
}

.basketSummaryCollapsibleWrapper,
.basketSummaryInfoWrapper,
.basketSummaryPriceWrapper {
    @include media-breakpoint-down(xl) {
        display: grid;
        gap: 10px;
    }
}
