.root {
    display: flex;
    align-items: center;
}

.icon {
    margin-left: 5px;
    width: 12px;

    path {
        stroke: var(--color-green);
    }
}
