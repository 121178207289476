.creditEntryDepositQuantityPicker {
  font-family: var(--font-monospace);
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.creditEntryDepositQuantityPickerSelect {
  width: 100%;
}