.accordionWrapper {
    display: flex;
    justify-content: center;
}

.root {
    width: 100%;
    max-width: 800px;
}

.accordionTitle {
    border-bottom: 1px solid var(--color-line);
    padding-bottom: 24px;
    font-size: 25px;
    line-height: 30px;
}

.content {
    padding: 16px 0;
}
