@import 'config/bundle';

.root {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    margin-top: 5px;
    margin-bottom: 25px;
    padding: 16px 16px 20px 16px;
    border-top: 1px solid var(--color-black-10);
    border-bottom: 1px solid var(--color-black-10);
    row-gap: 7px;
    column-gap: 10px;

    @include media-breakpoint-up(sm) {
        border: 1px solid var(--color-black-10);
    }

    @include media-breakpoint-up(xl) {
        max-width: 717px;
        margin-top: 0px;
    }
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
}

.basketWrapper {
    grid-row: 3/5;
    align-self: center;
    grid-column: 3/7;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    @include media-breakpoint-up(xxl) {
        grid-column: 5/7;
    }
}

.svhcTooltip {
    position: relative;
    width: 39px;
    height: 19px;
}

.favoriteBtn {
    align-self: center;
    justify-self: end;
    grid-column: 1/3;
}

.basketBtn {
    justify-self: end;
    width: 100%;
    grid-column: 5/7;
    align-self: center;
    @include media-breakpoint-up(xl) {
        grid-column: 6/7;
    }
    @include media-breakpoint-up(xxl) {
        grid-column: 5/7;
    }
}

.productInfoWrapper {
    grid-row: 2/3;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: 1fr 1fr min-content;
    margin-top: 20px;
    margin-bottom: 18px;
    align-self: center;
    row-gap: 4px;
    justify-self: auto;
    column-gap: 20px;
    @include media-breakpoint-up(xxl) {
        grid-row: 3/5;
        grid-column: 1/5;
        justify-self: flex-start;
    }
}

// .rows{
//   display:flex;
//   justify-content:space-between;
//   // border-bottom: 1px solid var(--color-black-10);
//   padding-top: 12px;
//   padding-bottom: 12px;
//   align-items:center;

// }

// .basketRow{
//   composes:rows;
//   border-bottom: none
// }

.brandCell {
    grid-row: 1/2;
    grid-column: 5/-1;
    justify-self: end;
    min-height: 20px;

    @include media-breakpoint-up(xxl) {
        grid-row: 1/2;
        grid-column: 1/4;
        justify-self: start;
    }
}

.titleCell {
    grid-row: 1/2;
    grid-column: 1/4;

    @include media-breakpoint-up(xxl) {
        grid-row: 2/3;
        grid-column: 1/4;
    }
}

.priceCell {
    grid-row: 3/6;
    grid-column: 1/2;
    align-self: center;
    justify-self: start;
    @include media-breakpoint-up(xxl) {
        grid-row: 1/3;
        grid-column: 4/7;
        justify-self: end;
    }
}

.depositCell {
    grid-row: 5/6;
    grid-column: 1/2;
    align-self: center;
    justify-self: start;
    @include media-breakpoint-up(xxl) {
        grid-row: 1/4;
        grid-column: 4/7;
        justify-self: end;
    }
}

.feeCell {
    grid-row: 6/6;
    grid-column: 1/2;
    align-self: center;
    justify-self: start;
    @include media-breakpoint-up(xxl) {
        grid-row: 2/4;
        grid-column: 4/7;
        justify-self: end;
    }
}
