.root {
    composes: cleanButton from global;
    white-space: nowrap;

    &:disabled,
    &.fetching {
        .iconWrapper {
            background-color: var(--color-disabled);
        }
        .text {
            color: var(--color-disabled);
        }

        .badge {
            background-color: var(--color-black-50);
        }
    }
}

.icon {
    path {
        stroke: var(--color-white-dimmed);
    }
}

.rightText {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.bottomText {
    display: grid;
    gap: 5px;
    justify-items: center;
}

.iconWrapper {
    flex-shrink: 0;
    position: relative;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.defaultSize {
    composes: iconWrapper;
    width: 40px;
    height: 40px;

    .icon {
        width: 20px;
    }
}

.smallSize {
    composes: iconWrapper;
    width: 30px;
    height: 30px;

    .icon {
        width: 15px;
    }
}

.badge {
    position: absolute;
    top: -3px;
    right: -3px;
}

.defaultStyle:not([disabled]) {
    color: var(--color-primary);
    .iconWrapper {
        background-color: var(--color-primary-20);
    }
    &:hover .iconWrapper {
        background-color: var(--color-primary-40);
    }

    &.fillIcon {
        .icon {
            path {
                stroke: transparent;
                fill: var(--color-primary);
            }
        }
    }
    &:not(.fillIcon) {
        .icon {
            path {
                stroke: var(--color-primary);
            }
        }
    }
}

.lightStyle:not([disabled]) {
    color: var(--color-primary);

    .iconWrapper {
        background-color: var(--color-primary-10);
    }
    &:hover .iconWrapper {
        background-color: var(--color-primary-20);
    }

    &.fillIcon {
        .icon {
            path {
                stroke: transparent;
                fill: var(--color-primary);
            }
        }
    }
    &:not(.fillIcon) {
        .icon {
            path {
                stroke: var(--color-primary);
            }
        }
    }
}

.darkStyle:not([disabled]) {
    color: var(--color-white);
    .iconWrapper {
        background-color: var(--color-primary);
    }
    &:hover .iconWrapper {
        background-color: var(--color-primary-60);
    }

    &.fillIcon {
        .icon {
            path {
                stroke: transparent;
                fill: var(--color-white);
            }
        }
    }

    &:not(.fillIcon) {
        .icon {
            path {
                stroke: var(--color-white);
            }
        }
    }
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
