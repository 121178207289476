.modalTitle {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.section {
    margin-bottom: 20px;
}

.header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 50px;
    grid-template-columns: repeat(auto-fill, auto);
    justify-content: space-between;
}
