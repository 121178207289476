.section {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 50px;
    grid-template-columns: repeat(auto-fill, auto);
    justify-content: space-between;
}

.buttonIcon {
    width: 20px;
    margin-right: 5px;

    path {
        stroke: var(--color-primary);
    }
}

.checkbox {
    display: flex;
    align-items: center;
}
