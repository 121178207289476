@import 'config/bundle';

.container {
    display: flex;
    align-items: center;
}

.productImageWrapper {
    padding: 2px;
    border: 1px solid var(--color-black-30);
    background-color: var(--color-white-dimmed);
    width: 30px;
    height: 38px;
    margin-right: 15px;
}

.productImage {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: relative;
}

.titleContainer {
    flex: 1;
}

.title {
    overflow-wrap: anywhere;
}
