.invoiceDetails {
    display: grid;
    gap: 20px 50px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
}

.requisitionInput {
    margin-top: 5px;
}
