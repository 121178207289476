.scrollToTopBtn {
    position: fixed;
    display: inline-block;
    bottom: calc(var(--grid-gutter-half) + 100px);
    right: 30px;
    transform: scale(0);
    opacity: 0;
    transition:
        transform var(--timing-normal) var(--easing-bounce-in),
        opacity var(--timing-normal) var(--easing-bounce-in);
}

.scrollToTopBtnActive {
    composes: scrollToTopBtn;
    opacity: 1;
    transform: scale(1);
}
