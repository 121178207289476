@import 'config/bundle';

.columnsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @include media-breakpoint-up(md) {
        gap: 1.4925373134328%;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.column {
    display: grid;
    gap: 20px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        gap: calc(1.4925373134328% * 3);
    }
}

.square {
    aspect-ratio: 1.075;
}

.rectangle {
    aspect-ratio: 2.2933;
}
