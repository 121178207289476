.root {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.requiredStar {
    font-size: 13px;
    line-height: 0;
    color: var(--color-primary);
}

.isHidden {
    opacity: 0;
    width: 0px;
    height: 0px;
    overflow: hidden;
}
