@import 'config/bundle';

.container {
    position: relative;
    width: 100%;
}

.field {
    height: var(--input-height);
    line-height: var(--input-height);
    padding: 0 var(--input-padding-side);
    padding-right: var(--input-height);
    background: var(--input-background-color);
    border: var(--input-border-default);
    font-weight: var(--font-weight-semi-bold);
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        border: var(--input-border-hover);
    }
}

.input {
    all: unset;
    width: 100%;
}

.icon {
    width: 20px;
    position: absolute;
    right: 10px;
    bottom: 9px;
}

.drawer {
    composes: cleanList from global;
    position: absolute;
    top: calc(var(--input-height) - 1px + 12px + 10px);
    left: 0;
    right: 0;
    z-index: 1;
    max-height: 180px;
    width: 100%;
    background: #fff;
    border: 1px solid var(--color-primary-20);
    overflow-y: scroll;
}

.hidden {
    visibility: hidden;
}

.control {
    border: 1px solid var(--color-primary-20);
    border-radius: 0 !important;
    background: var(--input-background-color) !important;
}

.input {
    font-family: var(--font-main) !important;
    font-weight: var(--font-weight-semi-bold) !important;
    color: var(--color-primary) !important;
    input {
        font-family: var(--font-main) !important;
        font-weight: var(--font-weight-semi-bold) !important;
        color: var(--color-primary) !important;
    }
}

.multiSelectItem {
    background-color: var(--color-primary-10);
    color: var(--color-primary);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        &:not([aria-disabled="true"]) {
            background-color: var(--color-white-dimmed);
            cursor: pointer;
        }
    }

    &[aria-disabled="true"] {
        cursor: default;
        color: var(--color-primary-20);
    }
}

.selected {
    font-weight: var(--font-weight-semi-bold);
}

.count {
    color: (var(--color-primary-60));
}
