@import 'config/bundle';

.item {
    background-color: var(--color-white);
    margin-top: 10px;
    padding: 10px;
    border: 1px solid var(--color-white);
    display: grid;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    grid-template-areas:
        'title-image  quantity'
        'item-id      checkbox'
        'price        price'
        'buttons      buttons';

    @include media-breakpoint-up(xl) {
        grid-template-columns: 30px 1fr 120px 120px 250px 150px;
        align-items: center;
        grid-template-areas: 'checkbox title-image item-id quantity buttons price';
    }
}

.selectedItem {
    border-color: var(--color-primary);
}

.checkboxCell {
    grid-area: checkbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media-breakpoint-up(xl) {
        justify-content: center;
    }
}

.imageTitleCell {
    grid-area: title-image;
}

.quantityCell {
    grid-area: quantity;
    text-align: right;
    @include media-breakpoint-up(xl) {
        text-align: left;
    }
}

.itemIdCell {
    grid-area: item-id;
}

.priceCell {
    grid-area: price;

    margin-top: 15px;

    @include media-breakpoint-up(xl) {
        text-align: right;
        margin-top: 0;
    }
}

.buttonsCell {
    grid-area: buttons;
    padding: 5px;
    text-align: center;
    // margin-top: 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: flex-end;

    @include media-breakpoint-up(xl) {
        justify-content: center;
        margin-top: 0;
    }
}

.label {
    display: none;

    @include media-breakpoint-up(xl) {
        display: block;
    }
}

.checkbox {
    margin-bottom: 0;
}

.itemLink {
    @include media-breakpoint-down(xl) {
        display: flex;
        column-gap: 10px;
        position: relative;
        top: -2px;
        padding-left: 45px;
    }
}
