.container {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
}

.item {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
