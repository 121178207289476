@import 'config/bundle';

.wrapper {
    height: auto;
    display: flex;
    justify-content: center;
}

.wrapperFixed {
    composes: wrapper;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    flex-direction: column;
    align-items: center;
    background-color: rgba(var(--color-code-black-5), 0.7);
    z-index: var(--z-index-high);
    // pointer-events: none;
}

.text {
    padding: 10px 0;
}

.loader {
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    // border-top-color: var(--color-primary);
    animation: spin 0.7s linear infinite;
    width: 50px;
    height: 50px;
}

.primaryColor {
    border-top-color: var(--color-primary);
}

.secondaryColor {
    border-top-color: var(--color-orange);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
