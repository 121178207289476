@import 'config/bundle';

.progressBar {
    position: relative;
    height: 6px;
    border-radius: 3px;
    background-color: var(--color-black-10);
    overflow: hidden;
}

.progress {
    display: block;
    position: absolute;
    background-color: var(--color-primary-60);
    height: 100%;
    left: 0;
    top: 0;
    transition: width var(--timing-slow) var(--easing-primary);
}
