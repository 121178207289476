@import 'config/bundle';

.attribute {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 15px;
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.attributeName {
    flex-grow: 0;
    flex-shrink: 0;
    text-transform: uppercase;
    overflow-wrap: anywhere;
    word-break: break-word; // for old browsers that don't support overflow-wrap: anywhere;

    @include media-breakpoint-up(lg) {
        flex-basis: 200px;
    }
}

.attributeValue {
    overflow-wrap: anywhere;
    word-break: break-word; // for old browsers that don't support overflow-wrap: anywhere;
}
