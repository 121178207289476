.tooltipWrapper {
    z-index: var(--z-index-tooltip);
    background-color: var(--color-background-blue);
    box-shadow: var(--shadow-focus);
    max-height: 100vh;
    overflow-y: auto;
}

.content {
    padding: 10px;
    border-bottom: 1px solid var(--color-primary-20);
}

.header {
    composes: content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.title {
    color: var(--color-primary-120);
}

.item {
    composes: content;
    padding: 15px 10px;
    composes: cleanButton from global;
    display: block;
    width: 100%;

    &:hover {
        background-color: var(--color-primary-10);
    }

    &:last-child {
        border-bottom: none;
    }
}
