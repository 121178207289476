.root {
    border-bottom: 1px solid var(--color-line);
}

.button {
    composes: cleanButton from global;
    padding: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    width: 15px;
    transition: transform var(--timing-normal) var(--easing-primary);

    path {
        stroke: var(--color-primary);
    }
}

.activeIcon {
    composes: icon;
    transform: rotate(45deg);
}

.content {
    padding: 10px 0;
}
