@import 'config/bundle';

.root {
    padding-left: 10px;
}

.header {
    display: grid;
    gap: 0 20px;
    grid-template-columns: 1fr;
    align-items: baseline;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr auto;
    }
}

.tableHeader {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
        z-index: var(--z-index-low);
        position: relative;
        padding: 0 10px;
        background-color: var(--color-primary-10);
        box-shadow: var(--shadow-focus);
    }
}

.tableHeaderItem {
    border-right: 1px solid var(--color-primary-20);
    padding: 15px 0;

    &:nth-last-child(-n + 3) {
        border-right: none;
    }
}

.headerButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.categoriesList {
    list-style: none;
    padding: 0;

    @include media-breakpoint-up(lg) {
        padding-left: 15px;
    }
}
