@import 'config/bundle';

.carInfo {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.licensePlate {
    margin-right: 20px;
}
