@import 'config/bundle';

.contentCarousel {
    position: relative;
}

.contentCarouselSlider {
    margin: 0 35px 25px;

    @include media-breakpoint-up(sm) {
        margin: 0 55px 25px;
    }
}

.contentCarouselHeading {
    margin-bottom: 25px;
    text-align: center;
}

.contentCarouselFixed {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.contentCarouselSlideMedia {
    position: relative;
    aspect-ratio: 1.125;
    overflow: hidden;
}

.contentCarouselSlideText {
    font-size: 14px;
    line-height: 21px;
    font-weight: var(--font-weight-semi-bold);
    margin-top: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contentCarouselSlideMediaPlaceholder {
    aspect-ratio: 1.125;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.contentCarouselButton {
    composes: cleanButton from global;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    color: var(--color-black-50);

    @include media-breakpoint-up(sm) {
        width: 40px;
        height: 40px;
    }

    svg {
        width: 10px;

        @include media-breakpoint-up(sm) {
            width: 20px;
        }

        path {
            stroke: var(--color-white);
        }
    }

    &:disabled {
        background-color: var(--color-disabled);
        color: var(--color-white);

        svg {
            path {
                stroke: var(--color-white);
            }
        }
    }

    &:not([disabled]) {
        background-color: var(--color-primary);
        color: var(--color-white);

        &:hover {
            background-color: var(--color-primary-60);
            color: var(--color-white);
        }

        &:focus {
            outline: 3px solid var(--color-orange-50);
        }
    }
}

.contentCarouselButtonBack,
.contentCarouselButtonNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: var(--z-index-low);
}

.contentCarouselButtonBack {
    left: 0;
}
.contentCarouselButtonNext {
    right: 0;
}
