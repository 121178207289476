.content {
    background-color: var(--color-primary-10);
    box-shadow: var(--shadow-focus);
    max-width: 350px;
    z-index: var(--z-index-high);
}

.contentWrapper {
    display: inline-block;
    padding: 10px 10px;
}

.fallbackIcon {
    border-radius: 100%;
    background-color: var(--color-primary);
    width: 15px;
    height: 15px;
    display: inline-block;

    path {
        stroke: transparent;
        fill: var(--color-white-dimmed);
    }
}
