@import 'config/bundle';

.section {
    @include media-breakpoint-up(lg) {
        gap: unset !important;
        margin-top: 10px !important;
    }
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary-10);
    padding: 20px;
}

.header {
    display: flex;
    height: 28px;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    width: 24px;
    height: 24px;
}

.title {
    margin-left: 10px;
}
