@import 'config/bundle';

.rootTop {
    @include media-breakpoint-up(sm) {
        position: relative;
        top: -30px;
    }
}

.headerWrapper {
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 20px;
}

.heading {
    display: inline-flex;
    align-items: baseline;
    gap: 10px;
}

.details {
    display: flex;
    gap: 10px 20px;
    justify-content: space-between;
    padding: 15px 0;
    flex-wrap: wrap;
    justify-content: space-between;
}

.controls {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.modalTitle {
    display: flex;
    gap: 20px;
    align-items: center;
}
