.root {
    composes: cleanButton from global;
    width: 450px;

    &:hover {
        .itemsWithGradient {
            &::after {
                background: linear-gradient(180deg, rgba(var(--color-code-primary-10), 0) 0%, rgba(var(--color-code-primary-10), 1) 100%);
            }
        }
    }
}

.header {
    display: grid;
    gap: 10px;
    grid-template-columns: auto minmax(0, 1fr) 60px auto;
    margin-bottom: 5px;
    align-items: center;
}

.saveCell {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.saveIcon {
    width: 24px;
    path {
        stroke: var(--color-primary);
    }
}

.productItem {
    margin-bottom: 5px;
}

.itemsWithGradient {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        z-index: 1;
        background: linear-gradient(180deg, rgba(var(--color-code-primary-5), 0) 0%, rgba(var(--color-code-primary-5), 1) 100%);
    }
}
