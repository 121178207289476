@import 'config/bundle';

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
}

.dot {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    border-radius: 999px;
    background: var(--color-black-30);
    transition: cubic-bezier(0.25, 1, 0.5, 1) background 300ms;

    &.active {
        background: var(--color-primary);
    }
}
