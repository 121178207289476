@import 'config/bundle';

.productCrossNumbers {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-inline: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--color-primary-40);

    @include media-breakpoint-up(sm) {
        border-left: 1px solid var(--color-primary-40);
        border-right: 1px solid var(--color-primary-40);
    }
}
