.galleryContainer {
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.placeholderImage {
    width: 100%;
    height: auto;
    max-height: 100%;
}

.brand {
    position: absolute;
    z-index: var(--z-index-low);
    top: 20px;
    right: 20px;
}
