@import 'config/bundle';

.header {
    padding: 10px 0;
    display: grid;
    gap: 10px 20px;
    align-items: center;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr auto;
    }
}
.navigation {
    display: flex;
    align-items: center;
}

.title {
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px solid var(--color-line);
}

.backButton {
    composes: cleanButton from global;
    background-color: var(--color-primary-40);
    border-radius: 100%;
    flex-basis: 30px;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backIcon {
    width: 20px;

    path {
        stroke: var(--color-primary);
    }
}

.additionalInfo {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 10px 50px;
    align-items: center;
}
