.iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
}

.textContent {
    margin: 10px auto;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
}
