@import 'config/bundle';

.siblingCategoryItem {
  display: block;
  height: 100%;
  padding-bottom: 15px;
  font-size: 12px;

  @include media-breakpoint-up(xl) {
    padding-bottom: 40px;
    font-size: var(--base-font-size);
  }

  &[aria-disabled="true"] {
    opacity: 0.4;

    &:hover {
      color: var(--color-primary);
    }
  }

  &:not(&[aria-disabled="true"]):hover {
    border-bottom: 1px solid var(--color-primary);
  }
}

.selected {
  border-bottom: 1px solid var(--color-primary);
}
