@import 'config/bundle';

.gridRow {
    display: grid;
    justify-content: space-between;
    gap: 0 10px;
    grid-template-columns: 40vw 1fr auto;
    grid-template-areas:
        'brand stock-status     stock-status'
        'details details   details'
        'item-no item-no   item-no'
        'price splash-cell  add-to-basket';

    @include media-breakpoint-up(xl) {
        gap: 10px;
        grid-template-columns: 69px 110px 1fr 140px 30px 110px 110px 1fr;
        grid-template-areas: 'brand item-no details ftz-code splash-cell price stock-status add-to-basket';
    }

    @include media-breakpoint-up(xxl) {
        grid-template-columns: 69px 17% 1fr 13% 40px 13% 110px 1fr;
    }
}

.header {
    composes: gridRow;
    background-color: var(--color-white-dimmed);
    display: none;
    padding-bottom: 10px;

    @include media-breakpoint-up(xl) {
        display: grid;
    }
}

.row {
    position: relative;
    composes: gridRow;
    background-color: var(--color-white-dimmed);
    padding: 10px 0;
    border-top: 1px solid var(--color-primary-20);

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    @include media-breakpoint-down(xl) {
        &:first-child {
            padding-top: 0;
            border-top: none;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: 5px 0;

        align-items: center;

        &:last-child {
            border-bottom: 1px solid var(--color-line);
            padding-bottom: 5px;
        }
    }
}

.originalPartSplash {
    @include media-breakpoint-up(xl) {
        top: 5px;
        position: absolute;
        left: -30px;
    }
}

.rowExpert {
    border-top-color: var(--color-green-70);
    background-color: var(--color-background-green) !important;
    + .row {
        border-top-color: var(--color-green-70);
    }
    &:last-child {
        border-bottom-color: var(--color-green-70);
    }
}

.ftzCodeTitle {
    visibility: var(--feature-price-visible);
}

.brandCell {
    grid-area: brand;

    @include media-breakpoint-up(xl) {
        padding-left: 5px;
    }
}

.itemNoCell {
    grid-area: item-no;
    padding-bottom: 10px;
    color: var(--color-primary);
    font-family: var(--font-monospace);
    font-weight: var(--font-weight-regular);

    @include media-breakpoint-up(xl) {
        padding-bottom: 0;
        display: block;
    }
}

.detailsCell {
    grid-area: details;
    font-size: 13px;
    line-height: 21px;

    @include media-breakpoint-down(xl) {
        padding: 5px 0 10px 0;
    }
}

.ftzCodeCell {
    grid-area: ftz-code;
    font-family: var(--font-monospace);
    font-size: 12px;
    line-height: 16px;
    visibility: var(--feature-price-visible);
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.splashCell {
    grid-area: splash-cell;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.priceCell {
    grid-area: price;
    font-family: var(--font-monospace);
    display: flex;
    align-items: center;
}

.stockStatusCell {
    grid-area: stock-status;
    @include media-breakpoint-down(xl) {
        display: flex;
        justify-content: flex-end;
    }
}

.addFavoriteCell {
    display: flex;
    padding: 0px;
}

.addToBasketCell {
    grid-area: add-to-basket;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;

    @include media-breakpoint-up(xl) {
        align-self: unset;
    }

    & .actionIconButtons {
        display: flex;
        gap: 10px;
    }
}

.itemNo {
    font-weight: var(--font-weight-regular);
    font-family: var(--font-monospace);
    font-size: 12px;
}

.showAllContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;

    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.variantsCount {
    color: var(--color-black-50);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;
}

.campaignSplash {
    position: absolute;
    left: -30px;
}

.wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.addToBasketWrapper {
    width: 80px;
}

.crossNumber {
    display: flex;
}
