@import 'config/bundle';

.button {
    composes: cleanButton from global;
    font-size: 13px;
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.buttonIcon {
    height: 15px;
    width: 15px;
    transform: rotate(45deg);
    transition: transform var(--timing-normal) var(--easing-primary);
    transform-origin: center center;
    margin-left: 2px;

    path {
        stroke: var(--color-primary);
    }
}

.buttonIconCollapsed {
    transform: rotate(0deg);
}
