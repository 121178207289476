@import 'config/bundle';

.basketSummaryPriceWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.basketSummaryPriceCaption {
    composes: caption from global;

    @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
    }
}
