@import 'config/bundle';

.input {
    width: 100%;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 797px;
}
.inputWrapper {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }
}
.button {
    width: 100%;

    @include media-breakpoint-up(md) {
        width: 158px;
    }

    &[disabled] {
        background-color: var(--color-primary-140);
        color: var(--color-white);
        cursor: not-allowed;
    }
}
.text {
    font-size: 14px;
    line-height: 21px;
}

.primaryText {
    font-size: 25px;
    line-height: 30px;
}

.inputPlaceholder {
    &::placeholder {
        font-size: 13px;
        font-style: italic;
        font-weight: var(--font-weight-light);
    }
}

.emailConsent {
    text-align: center;
    font-weight: var(--font-weight-regular);
    @include media-breakpoint-up(md) {
        text-align: left;
    }
}

.checkboxText {
    font-size: 12px;
    color: var(--color-primary);
    line-height: 18px;
    font-weight: var(--font-weight-regular);
}
