@import '../common/input-common.module.scss';

.wrapper {
    margin-top: 20px;
}

.input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.label {
    composes: inputCommon;
    margin-top: 10px;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    overflow: hidden;
}

.disabledLabel {
    composes: label;
    opacity: 0.4;
    pointer-events: none;
}

.labelText {
    padding: 0 20px;
}

.labelButton {
    background-color: var(--color-primary-20);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
}

.icon {
    width: 20px;
    margin-right: 5px;
    path {
        stroke: var(--color-primary);
    }
}

.filesList {
    composes: cleanList from global;
    margin-bottom: 10px;
}

.file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-line);
}

.fileName {
    color: var(--color-primary);
    padding-left: 10px;
}

.deleteBtn {
    composes: cleanButton from global;
    padding: 10px 20px;
}

.deleteIcon {
    width: 15px;
    path {
        stroke: var(--color-primary);
    }
}
