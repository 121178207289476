.panelCard {
    margin-bottom: 30px;
}

.formElement {
    display: block;
    margin-bottom: 20px;
}

.buttons {
    margin: 30px 0;
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
}

.sectionTitle {
    margin-bottom: 20px;
}

.selectAll {
    border-bottom: 1px solid var(--color-line);
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.checkboxContent {
    display: flex;
    gap: 10px;
}
