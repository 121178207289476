@import 'config/bundle';

.product {
    padding: 10px 0;
    border-bottom: 1px solid var(--color-line);
    font-size: 13px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns:
            minmax(400px, 3fr)
            minmax(100px, 1fr)
            minmax(100px, 1fr)
            minmax(100px, 1fr)
            minmax(100px, 1fr);
        grid-gap: 10px;
    }

    &:last-child {
        border-bottom: none;
    }
}

.displayPrice {
    text-align: right;
}

.link {
    @include media-breakpoint-down(xl) {
        margin-bottom: 15px;
        width: 100%;
    }
    font-weight: (--font-weight-regular);
}

.orderInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 50px;

    @include media-breakpoint-up(sm) {
        justify-content: center;
        grid-template-columns: auto auto auto;
    }

    @include media-breakpoint-up(xl) {
        justify-content: end;
    }
}

.labels {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.label {
    margin: 5px 10px;
    height: 30px;
    box-shadow: inset 0 0 0 1.5px var(--color-primary-40);
    border-radius: 15px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    letter-spacing: 0.6px;
    color: var(--color-primary);
    text-transform: uppercase;
    font-weight: var(--font-weight-semi-bold);
}

.icon {
    margin-bottom: 1px;
    margin-right: 7px;
    width: 20px;

    path {
        stroke: var(--color-primary);
    }
}
