@import 'config/bundle';

.topSection {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    column-gap: 60px;
    row-gap: 30px;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-up(xl) {
        grid-template-columns: minmax(0, 1fr) auto;
        align-items: flex-start;
    }
}

.title {
    overflow-wrap: anywhere;
    word-break: break-word; // for old browsers that don't support overflow-wrap: anywhere;
    margin-bottom: 35px;
}

.controls {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;

    @include media-breakpoint-up(xxl) {
        flex-wrap: nowrap;
    }
}

.inStockFilter {
    flex: 0 0 auto;
}

.noPadding {
    @include media-breakpoint-up(xl) {
        padding-bottom: 0;
    }
}

.headerContainer {
    border-bottom: 1px solid var(--color-line);
    padding-bottom: var(--grid-gutter-half);
    margin-bottom: var(--grid-gutter-half);
    display: grid;
    row-gap: var(--grid-gutter-half);

    @include media-breakpoint-up(xl) {
        align-items: start;
        column-gap: 30px;
        grid-template-columns: 1fr minmax(0, auto);
    }
}

.searchSubHeading {
    margin-bottom: 35px;
}
