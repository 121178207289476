@import 'config/bundle';

.form {
    margin-bottom: var(--grid-gutter-half);
}

.fieldset {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0 30px 0;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.select {
    flex: 1;
}

.buttons {
    display: flex;
    gap: var(--grid-gutter-half);
    align-items: center;
    justify-content: center;
}

.percentageBox {
    border-radius: var(--border-radius);
    background-color: rgba(255, 0, 0, 0.343);
    padding: var(--grid-gutter-half);
    text-align: center;
}

.blueBox {
    background-color: var(--color-background-blue);
    border: 1px solid var(--color-primary-20);
}

.yellowBox {
    background-color: var(--color-background-yellow);
    border: 1px solid var(--color-yellow-30);
}

.redBox {
    background-color: var(--color-background-error);
    border: 1px solid var(--color-red-30);
}

.greenBox {
    background-color: var(--color-success-light);
    border: 1px solid var(--color-green-30);
}

.percentage {
    margin: 10px 0;
}

.resultsSection {
    margin: var(--grid-gutter-half) 0;
}
