@import 'config/bundle';

.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @include media-breakpoint-up(md) {
        gap: 1.4925373134328%;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}

.imageContainer {
    grid-column: span 12;
    aspect-ratio: 0.8;

    @include media-breakpoint-up(md) {
        grid-column: span 3;
    }
}

.carouselContainer {
    grid-column: span 12;

    @include media-breakpoint-up(md) {
        grid-column: span 9;
    }
}
