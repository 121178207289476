@import 'config/bundle';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    border-bottom: none;
}

.container {
    position: relative;
    width: 100%;
    height: auto;
}

.items {
    height: auto;
}

.item {
    width: 100%;
    padding: 15px;
    scroll-snap-align: start;
}

.innerItem {
    height: auto;
}

.slider {
    display: flex;
    margin: 0 -15px;
    transition: cubic-bezier(0.25, 1, 0.5, 1) transform 200ms;
    transform: translate3d(var(--track-offset), 0, 0);

    &.jump {
        transition: none;
    }
}

.backButton,
.nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: var(--z-index-low);
}

.backButton {
    left: 0;
}
.nextButton {
    right: 0;
}
