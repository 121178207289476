.root {
    border-radius: 8px;

    font-size: 8px;
    height: 15px;
    min-width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    line-height: 1;
    color: var(--color-white);
}

.rootDefault {
    composes: root;
    background-color: var(--color-orange);
}

.rootDisabled {
    composes: root;
    background-color: var(--color-black-50);
}
