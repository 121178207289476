@import 'config/bundle';

.feedback {
    text-align: center;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headline {
    font-weight: var(--font-weight-semi-bold);
    font-size: 18px;
    margin: 10px 0 15px;
}

.text {
    white-space: pre-wrap;
    margin: 15px 10% 40px;
}

.type {
    position: relative;
    background-color: var(--color-primary);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    place-content: center;
    place-items: center;
}
.success {
    background-color: var(--color-success);
}
.error {
    background-color: var(--color-error);
}

.icon {
    width: 24px;
    height: 24px;

    path {
        stroke: var(--color-white);
    }
}
