@import 'config/bundle';

.basketCheckoutWrapper {
    position: relative;
    margin-top: 20px;
}

.basketCheckoutLoaderOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--color-code-white), 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.basketCheckoutHeadingSection {
    display: grid;
    align-items: center;
    gap: 20px 100px;

    @include media-breakpoint-up(xl) {
        grid-template-columns: 170px 1fr; // 170px estimated max width incl buffer
    }
}

.basketCheckoutTitleWrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include media-breakpoint-up(xl) {
        justify-content: flex-start;
    }
}

.basketCheckoutTitle {
    margin-right: 10px;
}

.basketCheckoutError {
    grid-column: 1 / -1;
}
