.reportErrorBtn {
    composes: cleanButton from global;
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    width: auto;
    right: 0;
}

.reportErrorIcon {
    background-color: var(--color-primary-20);
    border-radius: 100%;
    padding: 4px;

    path {
        stroke: var(--color-primary) !important;
    }
}
