@import 'config/bundle';

.wrapper {
    margin-bottom: 20px;
}

.header {
    display: grid;
    gap: 10px 30px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--color-primary-40);

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr auto;
    }
}
