.container {
    display: flex;
    justify-content: flex-end;
}

.button {
    border: none;
    background: none;
}

.text {
    text-decoration: underline;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.submitButton {
    align-self: flex-end;
}
