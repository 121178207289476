@import 'config/bundle';

.customerList {
    composes: cleanList from global;
    margin: 0;
    padding: 0;
}

.customer {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-line);

    &:nth-child(even) {
        background-color: var(--color-black-5);
    }

    &:last-child {
        border-bottom: 1px solid var(--color-line);
    }
}

.customerInfo {
    composes: cleanList from global;
    width: 100%;
    margin-right: 50px;
    display: grid;
    gap: 5px 20px;
    @include media-breakpoint-up(xl) {
        grid-template-columns: 70px 2fr 2fr 1fr;
        align-items: center;
    }
}

.departmentIdWrapper {
    font-size: 12px;
}

.departmentId {
    margin-left: 5px;
    font-size: 14px;
    font-family: var(--font-monospace);
    line-height: 19px;
}

.name {
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;
}

.address {
    font-size: 13px;
    line-height: 21px;
}

.phoneNumberWrapper {
    font-size: 12px;
}

.phoneNumber {
    font-family: var(--font-monospace);
    font-size: 12px;
    line-height: 16px;
}

.noResults {
    padding: var(--grid-gutter-width) 0;
    text-align: center;
}
