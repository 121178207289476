.error {
    margin: 0 calc(var(--related-products-gutter, 0) / 2);
}

.sectionTitle {
    margin-bottom: 30px;
}

.numberOfProducts {
    width: max-content;
    opacity: 0.7;
}
