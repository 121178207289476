@import 'config/bundle';

.orders {
    margin-bottom: var(--grid-gutter-width);
}

.header {
    display: grid;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
    grid-template-columns: [grid-start] 1fr auto [grid-end];

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr auto 1fr;
    }
}

.filterContainer {
    display: flex;
    align-items: center;
}

.filterLabel {
    display: none;

    @include media-breakpoint-up(lg) {
        composes: title from global;
        margin-right: 20px;
    }
}

.totalContainer {
    @include media-breakpoint-up(lg) {
        order: 1;
    }
}

.total {
    text-align: center;
    color: var(--color-black-50);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 21px;

    @include media-breakpoint-up(lg) {
        order: 2;
    }
}

.searchContainer {
    text-align: right;
    align-items: center;

    @include media-breakpoint-down(lg) {
        grid-column-start: grid-start;
        grid-column-end: grid-end;
    }

    @include media-breakpoint-up(lg) {
        order: 3;
        display: flex;
        justify-content: flex-end;
    }
}

.searchInput {
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
        width: 300px;
    }
}
