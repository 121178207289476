.basketItemsGroupCar {
    composes: basketItemsGroup from './../basket-items-group/basket-items-group.module.scss';
    padding: 15px;
    border: 1px solid var(--color-primary-20);
    background-color: var(--color-primary-10);
}

.basketItemsGroupCarHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.basketItemsGroupCarDeleteButton {
    composes: cleanButton from global;
    padding: 5px 10px;
    position: relative;
    margin: 5px 0 5px auto;
    color: var(--color-primary);
    display: inline-flex;
    width: auto;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 18px;
    text-align: right;
    white-space: nowrap;

    &:disabled {
        color: var(--color-primary-60);

        .basketItemsGroupCarDeleteButtonIcon path {
            stroke: var(--color-primary-60);
        }
    }
}

.basketItemsGroupCarDeleteButtonLoader {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.basketItemsGroupCarDeleteButtonIcon {
    width: 20px;
    height: 20px;
    margin-left: 5px;

    path {
        stroke: var(--color-primary);
    }
}
