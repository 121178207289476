.moduleRowContainer {
    background: var(--background-color);
}

.moduleRow {
    width: 100%;
    background: var(--background-color);
}

.moduleRowSpacingDefault:not(:last-child) {
    margin-bottom: var(--grid-gutter-half);
}

.moduleRowSpacingSmall {
    margin-bottom: var(--grid-gutter-half);
}
