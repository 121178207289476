@import 'config/bundle';

.basketHeaderNextDeliveryWrapper {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.basketHeaderNextDeliveryLabel {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0.6px;
}

.basketHeaderNextDeliveryCounter {
    margin: 5px 0;

    @include media-breakpoint-down(lg) {
        justify-content: flex-end;
    }
}
