@import 'config/bundle';

.container {
    width: 100%;
    position: relative;
}

.controls {
    display: grid;
    grid-template-columns: 1fr auto;
    bottom: 10px;
    gap: 10px;
}

.slide {
    aspect-ratio: 2.5;
    overflow: hidden;

    a {
        display: block;
    }
}

.controlsWrapper {
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.backButton,
.nextButton {
    border-radius: 50%;
}
