@import 'config/bundle';

$side-section-size: calc(50% - 25px);

.cardWrapper {
    &::after {
        content: '';
        display: block;
        clear: both;
        margin-bottom: 50px;
    }
}

.splashes {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 15px;
    display: grid;
    gap: 5px;
}

.tabContent {
    padding-inline: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--color-primary-40);

    @include media-breakpoint-up(sm) {
        border-left: 1px solid var(--color-primary-40);
        border-right: 1px solid var(--color-primary-40);
    }
}

.otherVariantsContent {
    composes: tabContent;
    padding-bottom: 8px;
}

.errorButtonWrapper {
    margin-top: var(--grid-gutter-half);
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-up(xl) {
        max-width: 717px;
        margin-left: 4vw;
        grid-column: 2;
    }
}

.accordionButton {
    composes: cleanButton from global;
    position: relative;
    padding: 10px 20px;
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    font-size: 14px;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid var(--color-black-10);

    &.isOpen {
        border-top: 1px solid var(--color-primary-40);

        .accordionIcon {
            transform: rotate(90deg);
        }

        @include media-breakpoint-up(sm) {
            border-left: 1px solid var(--color-primary-40);
            border-right: 1px solid var(--color-primary-40);
        }
    }
}

.bottomSection {
    padding: var(--box-padding);
}

.accordionIcon {
    transition: transform var(--easing-primary) var(--timing-normal);
    width: 24px;

    path {
        stroke: var(--color-primary);
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-bottom: 1px solid var(--color-black-10);

    @include media-breakpoint-up(sm) {
        border-left: 1px solid var(--color-black-10);
        border-right: 1px solid var(--color-black-10);
    }
}

.mainContainer {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 390px auto;
    margin-top: 30px;

    @include media-breakpoint-up(sm) {
        grid-template-rows: 505px auto;
    }

    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: 492px auto;
        grid-template-rows: auto;
    }
}

.cardRight {
    margin-left: 0;
    @include media-breakpoint-up(xl) {
        margin-left: 4vw;
        max-width: 717px;
        gap: 16px;
    }
}

.imageBox {
    position: relative;
    grid-column: 1/-1;
    justify-content: center;
    height: auto;
    @include media-breakpoint-up(xl) {
        grid-column: 1/2;
        height: 505px;
    }
}

.containerFooter {
    width: 100%;
    margin-top: 50px;
    // max-width: 1440px;
    padding-inline: var(--grid-gutter-half);
    margin-left: auto;
    margin-right: auto;
}

.listClass {
    grid-template-columns: auto;
}
