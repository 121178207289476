@import 'config/bundle';

.heading {
    margin-bottom: 20px;
}

.invoiceInfo {
    composes: cleanButton from global;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
    justify-content: start;

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.vehicles {
    margin-top: 20px;
    display: grid;
    justify-content: start;
    gap: 10px;
}
