.container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.svgIcon {
    width: 24px;
}

.labelIcon {
    composes: svgIcon;
}

.gripIcon {
    composes: svgIcon;
    margin-right: 10px;
    path {
        stroke: var(--color-primary);
    }
}

.categoryIconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.categoryIconSummer {
    composes: svgIcon;
    path {
        stroke: var(--color-orange);
    }
}

.categoryIconWinter {
    composes: svgIcon;
    path {
        stroke: var(--color-primary);
    }
}

.propertyCell {
    display: flex;
    align-items: center;
}

.additionalProperties {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 80px;
    height: 150px;
}

.additionalProperty {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.additionalPropertyText {
    margin-left: 10px;
}

.labelBadge {
    margin-left: 15px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 14px;
    height: 14px;
    font-size: 13px;
    position: relative;
    color: var(--color-white);
    background-color: var(--color-black-50);
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0;

    &::before {
        position: absolute;
        right: 100%;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right-width: 7px;
        border-right-style: solid;
        border-right-color: var(--color-black-50);
    }
}

// fuel
.labelFuelA {
    background-color: var(--color-green);
    &::before {
        border-right-color: var(--color-green);
    }
}
.labelFuelB {
    background-color: #87c907;
    &::before {
        border-right-color: #87c907;
    }
}

.labelFuelC {
    background-color: var(--color-yellow-120);
    &::before {
        border-right-color: var(--color-yellow-120);
    }
}

.labelFuelD {
    background-color: var(--color-orange);
    &::before {
        border-right-color: var(--color-orange);
    }
}

.labelFuelE {
    background-color: var(--color-error);
    &::before {
        border-right-color: var(--color-error);
    }
}
.labelFuelF {
    background-color: var(--color-error);
    &::before {
        border-right-color: var(--color-error);
    }
}
.labelFuelG {
    background-color: var(--color-error);
    &::before {
        border-right-color: var(--color-error);
    }
}

// grip
.labelGripA {
    background-color: var(--color-primary);
    &::before {
        border-right-color: var(--color-primary);
    }
}
.labelGripB {
    background-color: var(--color-primary-80);
    &::before {
        border-right-color: var(--color-primary-80);
    }
}
.labelGripC {
    background-color: var(--color-primary-60);
    &::before {
        border-right-color: var(--color-primary-60);
    }
}
.labelGripD {
    background-color: var(--color-primary-40);
    &::before {
        border-right-color: var(--color-primary-40);
    }
}
.labelGripE {
    background-color: var(--color-primary-20);
    &::before {
        border-right-color: var(--color-primary-20);
    }
}
.labelGripF {
    background-color: var(--color-primary-20);
    &::before {
        border-right-color: var(--color-primary-20);
    }
}
.labelGripG {
    background-color: var(--color-primary-20);
    &::before {
        border-right-color: var(--color-primary-20);
    }
}

// noise
.labelNoiseA {
    background-color: var(--color-green);
    &::before {
        border-right-color: var(--color-green);
    }
}
.labelNoiseB {
    background-color: var(--color-yellow-120);
    &::before {
        border-right-color: var(--color-yellow-120);
    }
}
.labelNoiseC {
    background-color: var(--color-orange);
    &::before {
        border-right-color: var(--color-orange);
    }
}
