.inputWrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.inputCommon {
    font-weight: var(--font-weight-semi-bold);
    appearance: none;
    border-radius: 0;
    outline: none;
    border: var(--input-border-default);
    background-color: var(--input-background-color);
    width: 100%;
    padding: 0 var(--input-padding-side);

    &:hover {
        border: var(--input-border-hover);
    }

    &:focus {
        border: var(--input-border-focus);
    }

    // &:not([value=""]):not(:focus):not(.readOnlyInput) {
    //   border-color: red;
    // }

    &:disabled {
        border: var(--input-border-disabled);
        color: var(--input-disabled-text-color);
    }
}

.invalid {
    border: var(--input-border-error);
    &:focus,
    &:hover {
        border: var(--input-border-error);
    }
}

.readOnlyInput {
    border: var(--input-border-disabled);
    color: var(--input-disabled-text-color);
    pointer-events: none;
    &:hover {
        border: var(--input-border-disabled);
    }
}

.bottomSection {
    display: flex;
    justify-content: space-between;
}
