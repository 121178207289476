@import 'config/bundle';

.basketHeaderWrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;

    @include media-breakpoint-up(sm) {
        align-items: center;
    }
}

.basketHeaderTitleSection {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-right: auto;
    row-gap: 10px;
    column-gap: 10px;
    @include media-breakpoint-up(sm) {
        flex-wrap: nowrap;
    }
}

.basketHeaderTitle {
    column-gap: 10px;
    display: flex;
    align-items: baseline;
    flex: 1;
}

.basketHeaderPrintButton {
    position: relative;
    top: 5px;
}

.basketHeaderButtonsWrapper {
    width: 100%;
    display: grid;
    gap: 10px;

    @include media-breakpoint-up(lg) {
        display: flex;
        column-gap: 10px;
        width: auto;
        margin-top: 0;
    }
}
