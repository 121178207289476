.pageTitle {
    margin-top: 0;
    padding-top: 0;
}

.itemTitle {
    font-size: 13px;
    line-height: 21px;
}

.radioWrapper {
    margin-bottom: 10px;
}

.dropdown {
    width: 100%;
    margin-top: 5px;
}

.input {
    margin-bottom: 20px;
}

.buttons {
    margin: 50px auto;
    display: flex;
    justify-content: space-around;
}

.submitButton {
    margin-left: 20px;
}

.errorMessage {
    composes: errorMessage from global;
}

.cancelRedirectLink {
    display: block;
    text-align: center;
    width: 100%;
}
