@import 'config/bundle';

.navigationWrapper {
    padding-bottom: 0;
    border-bottom: none;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-line);
    }

    @include media-breakpoint-up(xxxl) {
        align-items: baseline;
    }
}

.hideOnMobile {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.title {
    margin-bottom: 10px;

    @include media-breakpoint-up(xxxl) {
        margin-bottom: 0;
    }
}

.navigation {
    width: 100%;
    align-self: baseline;

    justify-content: end;

    @include media-breakpoint-up(xxxl) {
        width: auto;
    }
}

.addCreditNavWrapper {
    display: flex;
    gap: 30px;
    align-items: baseline;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.item {
    margin-left: 30px;

    &:first-child {
        margin-left: 0;
    }
}

.link {
    padding: 15px 0 25px 0;
    display: inline-block;
    font-weight: var(--font-weight-semi-bold);
    border-bottom: 4px solid transparent;
}

.selected {
    color: var(--body-color);
    border-bottom: 4px solid var(--color-primary);
}
