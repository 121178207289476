@import 'config/bundle';

.addToBasketQuantityInputWrapper {
    position: relative;
    display: block;
    margin: 0;
    width: auto;
    min-width: 30px;
    height: 30px;
    padding: 2px 7px;
    text-align: center;
    color: transparent;
}

.addToBasketQuantityInputWrapperLarge {
    min-width: 40px;
    height: 40px;
    padding: 9px 7px;
}

.addToBasketQuantityInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-orange);
    border-radius: 0;
    text-align: center;

    &:focus {
        outline: 0;
    }
}
