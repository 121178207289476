.flexContainer {
    display: flex;
}

.darken {
    position: relative;

    &::after {
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.alignLeft {
    justify-content: flex-start;
}

.alignRight {
    justify-content: flex-end;
}

.alignCenter {
    justify-content: center;
}
