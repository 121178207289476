@import '../common/input-common.module.scss';

.selectWrapper {
    position: relative;
}

.select {
    composes: inputCommon;
    height: var(--input-height);
    padding-right: 45px;
}

.icon {
    pointer-events: none;
    width: 24px;
    position: absolute;
    right: 10px;
    top: 8px;

    path {
        stroke: var(--color-primary);
    }
}

.iconDisabled {
    composes: icon;

    path {
        stroke: var(--color-disabled);
    }
}
