@import 'config/bundle';

.heading {
    display: flex;
    align-items: center;
}

.expertToggle {
    margin-left: auto;
}
