@import 'config/bundle';

.headerWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    @include media-breakpoint-up(lg) {
        grid-template-columns: auto 1fr;
    }
}

.inputWrapper {
    grid-row: 3/4;
    @include media-breakpoint-up(lg) {
        grid-column: 2/3;
        grid-row: 1/2;
    }
}
