.root {
    display: inline-block;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: transparent;
}

.dot-0 {
    composes: root;
    background-color: var(--color-green);
}

.dot-1 {
    composes: root;
    background-color: var(--color-yellow-120);
}

.dot-3 {
    composes: root;
    background-color: var(--color-primary);
}

.dot-4,
.dot-5 {
    composes: root;
    background: linear-gradient(to right, var(--color-primary) 50%, var(--color-yellow) 50%);
}
