@mixin desktop {
    @media (min-width: 1350px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 800px) {
        @content;
    }
}

.basketItemsLineItemWrapper {
    background-color: var(--color-white-dimmed);
    border: 1px solid var(--color-black-30);
    padding: 10px;
    margin-top: 10px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 10px;
    grid-template-areas:
        'item-name  item-name item-name  item-name      item-name      delete'
        'item-id    item-id   item-id    item-id        item-id        item-id'
        'ftz-code   ftz-code  ftz-code   stock-status   stock-status   stock-status'
        'price      price     price      add-to-basket  add-to-basket  add-to-basket';

    @include tablet() {
        gap: 10px;
        grid-template-areas:
            'item-name  item-name  item-name  item-id  ftz-code       stock-status'
            'price      price      price      delete   add-to-basket  add-to-basket';
    }

    @include desktop() {
        grid-template-columns: 335px 2fr 2fr 2fr 110px 1fr 145px;
        grid-template-areas: 'item-name item-id ftz-code stock-status add-to-basket delete price';
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.basketItemsLineItemWrapperWithCarInfo {
    border-color: var(--color-primary-20);
    grid-template-columns: 2fr 1fr 1fr 110px 30px;

    @include tablet() {
        grid-template-columns: 200px 1fr 1fr 1fr 110px 30px;
    }

    @include desktop() {
        grid-template-columns: 320px 2fr 2fr 2fr 110px 1fr 130px;
    }
}

.basketItemsLineItemNameCell {
    grid-area: item-name;
}

.basketItemsLineItemIdCell {
    display: none;
    grid-area: item-id;

    @include tablet() {
        display: block;
    }
}

.basketItemsLineItemFtzCodeCell {
    display: none;
    grid-area: ftz-code;

    @include tablet() {
        display: block;
    }
}

.basketItemsLineItemStockStatusCell {
    grid-area: stock-status;
    display: flex;
    justify-content: flex-end;
    margin: 15px 0 5px;

    @include tablet() {
        margin: 0;
    }
}

.basketItemsLineItemAddToBasketCell {
    grid-area: add-to-basket;
    align-self: end;
    margin-right: -5px;

    @include desktop() {
        align-self: center;
        margin-right: unset;
    }
}

.basketItemsLineItemDeleteCell {
    grid-area: delete;

    @include desktop() {
        display: flex;
        justify-content: center;
    }
}

.basketItemsLineItemPriceCell {
    grid-area: price;
    text-align: left;
}

.basketItemsLineItemCellCaption {
    display: none;
    margin-bottom: 3px;

    @include tablet() {
        display: block;
    }
}
