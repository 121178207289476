@import 'config/bundle';

.card {
    position: relative;
    width: 100%;
    height: 420px;
    border: 1px solid var(--color-black-30);
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
}

.content {
    margin-top: 20px;
    flex: 1;
}

.ftzPrice {
    margin-top: 5px;
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.footer {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 1fr auto auto;
    gap: 10px;
}

.brandStockContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
}

.stockStatus {
    margin-left: auto;
}

.link {
    color: inherit;
    font-weight: inherit;
}
