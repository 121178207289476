.root {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &.disabled {
        cursor: default;
    }
}

.input {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    width: 0px;
    height: 0px;
}

.checkBox {
    width: 20px;
    height: 20px;
    border: var(--input-border-default);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    &:hover {
        border: var(--input-border-hover);
    }

    &:invalid {
        border: var(--input-border-error);
    }

    &.error {
        border: var(--input-border-error);
    }

    &.disabledCheckbox {
        border: var(--input-border-disabled);
    }
}

.icon {
    opacity: 0;
    width: 12px;

    path {
        stroke: var(--color-green);
    }
}

.input {
    &:checked {
        & + .checkBox {
            border: var(--input-border-active);
            background-color: var(--color-background-blue);

            .icon {
                opacity: 1;
            }
        }
    }
}

.label {
    margin-left: 10px;

    &.disabledLabel {
        color: var(--input-disabled-text-color);
    }
}

.halfCheckedInner {
    width: 10px;
    height: 10px;
    background-color: var(--color-primary-40);
    display: inline-block;
}

.white {
    background: #fff;
}
