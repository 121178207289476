@import 'config/bundle';

.attributeGroup {
    margin-bottom: 20px;

    dd {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;
    }
}

.attributeGroupTitle {
    font-size: 13px;
    font-weight: var(--font-weight-semi-bold);
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 10px;
}

.attribute {
    font-weight: var(--font-weight-regular);
    white-space: nowrap;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    color: var(--color-black-70);
    box-shadow: 0 0 0 1px var(--color-black-30);
    transition: box-shadow var(--easing-primary) var(--timing-normal), background-color var(--easing-primary) var(--timing-normal),
        color var(--easing-primary) var(--timing-normal);
    background-color: transparent;
    appearance: none;

    &:hover {
        color: inherit;
    }
}

.possibleChoice {
    color: var(--color-black);
    box-shadow: 0 0 0 1px var(--color-black-30);
    background-color: var(--color-black-10);
}

.selected {
    color: var(--color-black);
    box-shadow: 0 0 0 2px var(--color-primary);
    background-color: var(--color-black-10);
}

.primaryAttribute {
    color: var(--color-primary);
    font-weight: var(--font-weight-semi-bold);
    background-color: var(--color-white-dimmed);
    box-shadow: 0 0 0 2px var(--color-primary), var(--shadow-focus);

    &:hover {
        color: var(--color-primary);
    }
}
