@import 'config/bundle';

.backButton,
.nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: var(--z-index-low);
}

.backButton {
    left: 0;
}
.nextButton {
    right: 0;
}
