@import 'config/bundle';

.basketCheckoutDeliveryWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px 20px;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr;
    }
}

.basketCheckoutCollapseWrapper {
    grid-column: 1/4;

    @include media-breakpoint-up(lg) {
        grid-column: 1/3;
    }
}

.basketCheckoutCommentWrapper {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--color-primary-20);
}
