@import 'config/bundle';

.button {
    display: flex;
    width: auto;
    align-items: center;
    appearance: none;
    border: none;
    background-color: transparent;
    color: var(--color-black-70);
    font-size: 12px;
    line-height: 15px;
    font-weight: var(--font-weight-semi-bold);
    text-align: left;
    padding: 0;
    white-space: nowrap;

    &:focus {
        outline: none;
    }

    &::before {
        content: '';
        display: inline-block;
        flex: 0 0 auto;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: var(--color-black-30);
    }
}

.buttonIcon {
    width: 15px;
    margin-left: -2.5px;

    path {
        stroke: var(--color-black-70);
    }
}

.text {
    margin-left: 4px;
    text-wrap: wrap;
}

// PrimaryDepartment
.status-0 {
    &::before {
        background-color: var(--color-green);
    }
}

// NearbyDepartment
.status-1 {
    &::before {
        background-color: var(--color-yellow-120);
    }
}

// NotInStock
.status-2 {
    &::before {
        display: none;
    }
}

// CentralDepartment
.status-3 {
    &::before {
        background-color: var(--color-primary);
    }
}

.status-4,
.status-5 {
    &::before {
        background: linear-gradient(to right, var(--color-primary) 50%, var(--color-yellow) 50%);
    }
}
