.root {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.maxLength {
    margin-left: auto;
    color: var(--color-black);
    font-size: 12px;
    font-weight: bold;
}
