@import 'config/bundle';

.details {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: auto auto;

    @include media-breakpoint-up(lg) {
        grid-template-columns: auto auto auto;
    }
}
