@import 'config/bundle';

$button-arrow-size: 4px;

.table {
    th:not(:last-child) {
        border-right: 1px solid var(--color-primary-20);
    }
}

.cellContent {
    // padding: 10px;
    min-height: 30px;
    display: flex;
    align-items: center;

    &:first-child {
        padding-left: 20px;
    }
    &:last-child {
        padding-right: 20px;
    }
}

.tableHead {
    background-color: var(--color-primary-10);
    box-shadow: var(--shadow-focus);

    th {
        white-space: nowrap;

        font-size: 14px;
        font-weight: var(--font-weight-regular);
        text-transform: uppercase;
        text-align: left;
    }
}

.tableBody {
    tr {
        &.linkRow {
            &:hover {
                background-color: var(--color-black-10);
            }
        }
    }
}

.sortBtn {
    font-size: inherit;
    text-transform: inherit;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-black);

    &:focus {
        outline: none;
    }
}

.buttonArrows {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.buttonArrow {
    width: 0;
    border-left: $button-arrow-size solid transparent;
    border-right: $button-arrow-size solid transparent;
}

.buttonArrowUp {
    composes: buttonArrow;
    border-bottom-style: solid;
    border-bottom-width: $button-arrow-size;
    margin-bottom: 4px;
}

.buttonArrowDown {
    composes: buttonArrow;
    border-top-style: solid;
    border-top-width: $button-arrow-size;
}

.buttonArrowUpInactive {
    composes: buttonArrowUp;
    border-bottom-color: var(--color-primary-40);
}

.buttonArrowUpActive {
    composes: buttonArrowUp;
    border-bottom-color: var(--color-primary);
}

.buttonArrowDownInactive {
    composes: buttonArrowDown;
    border-top-color: var(--color-primary-40);
}

.buttonArrowDownActive {
    composes: buttonArrowDown;
    border-top-color: var(--color-primary);
}

.link {
    composes: cellContent;
    font-weight: var(--font-weight-regular);
}
