@import 'config/bundle';

.addToBasketWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.addToBasketWrapperNoFullWidth {
    width: auto;
}

.addToBasketContainer {
    position: relative;
    display: inline-block;
    width: auto;
}
